// import React, {useState} from 'react';

// const notifications = [
//   {
//     id: 1,
//     icon: 'fa-mail',
//     title: 'Launchday 1.4.0 update email sent',
//     text: 'Sent to all 1,851 subscribers over a 24 hour period',
//     time: '2m ago',
//   },
//   {
//     id: 2,
//     icon: 'fa-archive',
//     title: "New project 'Goodkit' created",
//     text: 'Looks like there might be a new theme soon.',
//     time: '2h ago',
//   },
//   {
//     id: 3,
//     icon: 'fa-code',
//     title: 'Dashkit 1.5.0 was deployed.',
//     text: 'A successful deploy to production was executed.',
//     time: '2m ago',
//   },
// ];
const NotificationBellDropdown = ({
  notifications,
  count,
  markRead,
}: {
  notifications: any;
  count: number;
  markRead: any;
}) => {
  return (
    <div>
      {/* Notification Bell */}
      <a
        className='navbar-user-link mb-3 position-relative'
        data-bs-toggle='offcanvas'
        href='#sidebarOffcanvasActivity'
        aria-controls='sidebarOffcanvasActivity'
      >
        <span className='icon'>
          <i
            className='fa fa-bell text-primary'
            style={{fontSize: '1.5rem'}}
          ></i>
        </span>
        {/* {unreadCount > 0 && ( */}
        <span
          className={`badge bg-danger position-absolute top-${-2} start-100 translate-middle`}
          style={{fontSize: '0.65rem'}}
        >
          {count}
        </span>
        {/* )} */}
      </a>

      {/* Offcanvas: Activity */}
      <div
        className='offcanvas offcanvas-start'
        id='sidebarOffcanvasActivity'
        tabIndex={-1}
      >
        <div className='offcanvas-header'>
          <h4 className='offcanvas-title'>Notifications</h4>
          <button
            type='button'
            className='btn-close text-danger cursor-pointer'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          ></button>
        </div>
        <div className='offcanvas-body'>
          <div className='list-group list-group-flush list-group-activity my-n3'>
            {notifications?.map((notification: any) => (
              <span
                key={notification.id}
                className={`list-group-item text-reset cursor-pointer ${
                  !notification.read ? 'bg-primary mt-1' : ''
                }`}
                onClick={() => markRead(notification.id)}
              >
                <div className='row'>
                  <div className='col-auto'>
                    <div className='avatar avatar-sm'>
                      <div className='avatar-title fs-lg bg-primary-soft rounded-circle text-primary'>
                        <i className={`fa ${notification.icon}`}></i>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col ms-n2  ${
                      !notification.read ? 'text-white' : 'text-gray-700'
                    }`}
                  >
                    <h5 className='mb-1'>{notification.title}</h5>
                    <p
                      className={`small ${
                        !notification.read ? 'text-white' : 'text-gray-700'
                      } mb-0`}
                    >
                      {notification.message}
                    </p>
                    <small className=' fw-bolder'>
                      {' '}
                      {new Date(
                        notification.createdAt.toLocaleString()
                      ).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </small>
                  </div>
                </div>
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationBellDropdown;

// import React from 'react';
// import Dropdown from 'react-bootstrap/Dropdown';
// import Badge from 'react-bootstrap/Badge';

// const NotificationBellDropdown = ({
//   notificationCount,
//   notifications,
// }: {
//   notificationCount: number;
//   notifications: string[];
// }) => {
//   return (
//     <Dropdown align='end'>
//       <Dropdown.Toggle
//         variant='light'
//         id='dropdown-basic'
//         style={{
//           display: 'inlineBlock',
//           position: 'relative',
//           cursor: 'pointer',
//         }}
//         className='p-0'
//       >
//         <i className='bi bi-bell fs-4'></i>
//         {notificationCount > 0 && (
//           <Badge
//             pill
//             bg='danger'
//             style={{
//               position: 'absolute',
//               top: '-8px',
//               right: '-10px',
//               fontSize: '0.75rem',
//               padding: '0.35em 0.6em',
//               backgroundColor: '#dc3545',
//               borderRadius: '50%',
//               color: 'white',
//               zIndex: 1,
//             }}
//           >
//             {notificationCount}
//           </Badge>
//         )}
//       </Dropdown.Toggle>

//       <Dropdown.Menu>
//         {notifications.length > 0 ? (
//           notifications.map((notification, index) => (
//             <Dropdown.Item key={index} href='#/action-1'>
//               {notification}
//             </Dropdown.Item>
//           ))
//         ) : (
//           <Dropdown.Item disabled>No new notifications</Dropdown.Item>
//         )}
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// };

// export default NotificationBellDropdown;
