import {useSessionStorage} from '../../hooks';
import {
  ChangePasswordRequest,
  ChangePinRequest,
  CreatePinRequest,
} from '@interfaces/user.interface';
import {DashboardLayout} from '@layouts/DashboardLayout';
import {
  changePasswordReq,
  changePin,
  createPin,
  getUserNotifications,
  updateProfile,
} from '@services/krent.service';
import {useEffect, useState} from 'react';
import ktoast from '@services/toast.service';
import {BaseApiResponse} from '@interfaces/app.interface';
import {NotificationCard} from '@shared/cards';
import {NotificationsInterfaceResponse} from '@interfaces/notifications.interface';
import emptyNotifications from '../../assets/images/notifications.png';
// import Select from 'react-select';
import {useNavigate} from 'react-router-dom';
import customFetch from '@services/axio';
// import {toast} from 'react-toastify';
import {AgentFormInput} from './agentFormInput';
import {PersonalFormInput} from './PersonalFormInput';

export const Settings = () => {
  // type Bank = {
  //   value: string;
  //   label: string;
  // };

  const navigate = useNavigate();
  const [user] = useSessionStorage('user', {});
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordMatchError, setPasswordMatchError] = useState<boolean>(false);
  const [pinMatchError, setPinMatchError] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('');
  const [timeZone, setTimeZone] = useState<string>('');
  const [currency, setCurrency] = useState<string>('');
  const [kyc, setKyc] = useState<any>('');
  const [agentKyc, setAgentKyc] = useState<any>('');
  const [previewUrl, setPreviewUrl] = useState<any>('');
  const [file, setFile] = useState<any>(null);
  const [agentPreviewUrl, setAgentPreviewUrl] = useState<any>('');
  const [agentFile, setAgentFile] = useState<any>(null);

  // const [kycType, setKycType] = useState('');
  const [kycData, setKycData] = useState({
    photo: '',
    documentID: '',
    documentType: '',
    expirationDate: '',
    agentId: '',
    agentIdNumber: '',
  });

  // const [photo,setPhoto]= useState('')
  // const [documentID,setDocumentID] = useState('')
  // const [documentType,setDocumentType] = useState('')
  // const [expiryDate,setExpiryDate] = useState('')

  const [notifications, setNotifications] = useState<
    NotificationsInterfaceResponse[]
  >([]);

  // const [accountNumber, setAccountNumber] = useState('');
  // const [bankCode, setBankCode] = useState('');
  // const [accountName, setAccountName] = useState('');
  // const [banks, setBanks] = useState<Bank[]>([]);
  // const [filteredBanks, setFilteredBanks] = useState<Bank[]>([]);

  // const [bankLoading, setBankLoading] = useState(false);

  const handlePasswordChange = (e: any) => {
    e.preventDefault();
    setLoading(true);
    const {password, newPassword, conPassword} = e.target;
    if (newPassword.value === conPassword.value) {
      const abortController = new AbortController();
      const payload: ChangePasswordRequest = {
        oldPassword: password.value,
        passwordConfirm: conPassword.value,
        password: newPassword.value,
      };
      changePasswordReq(user?.id, abortController.signal, payload)
        .then((res: BaseApiResponse<{}>) => {
          if (res.success) {
            ktoast.success('Password changed successfully');
            navigate('/sign-in');
          }
        })
        .catch((e) => {
          ktoast.error('Sorry an error occurred');
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setPasswordMatchError(true);
    }
  };

  // Create Pin
  const handleCreatePin = (e: any) => {
    e.preventDefault();
    setLoading(true);
    const {newPin, conPin} = e.target;
    if (newPin.value === conPin.value) {
      const abortController = new AbortController();
      const payload: CreatePinRequest = {
        walletId: user.wallet,
        pinConfirm: conPin.value,
        pin: newPin.value,
      };
      createPin(abortController.signal, payload)
        .then((res: BaseApiResponse<{}>) => {
          if (res.success) {
            ktoast.success('Pin created successfully');

            newPin.value = '';
            conPin.value = '';
          }
        })
        .catch((e) => {
          ktoast.error('Sorry an error occurred');
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setPinMatchError(true);
      setLoading(false);
    }
  };

  // Handle Pin Change
  const handlePinChange = (e: any) => {
    e.preventDefault();
    setLoading(true);
    const {pin, newPin, conPin} = e.target;
    if (newPin.value === conPin.value) {
      const abortController = new AbortController();
      const payload: ChangePinRequest = {
        oldPin: pin.value,
        pinConfirm: conPin.value,
        pin: newPin.value,
      };
      changePin(user?.id, abortController.signal, payload)
        .then((res: BaseApiResponse<{}>) => {
          if (res.success) {
            ktoast.success('Pin changed successfully');
            pin.value = '';
            newPin.value = '';
            conPin.value = '';
          }
        })
        .catch((err) => {
          ktoast.error(err.errors[0].message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setPinMatchError(true);
      setLoading(false);
    }
  };

  const handleSubmitPreference = (e: any) => {
    e.preventDefault();
    const abortController = new AbortController();
    if (language || currency || timeZone) {
      updateProfile(
        user?.id,
        {
          language,
          currency,
          timezone: timeZone,
        },
        abortController.signal
      )
        .then(() => {
          // getProfile(abortController.signal).then((res: any) => {
          // setUser(res.data);
          // });
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            navigate('/profile');
          }, 1000);
        });
    }
  };

  const handleKycOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    let value: string | FileList | null;

    if (name === 'photo' || name === 'agentId') {
      value = e.target.files;
      if (value && value.length > 0) {
        const [file] = value;
        if (name === 'photo') {
          setFile(value[0]);
          setPreviewUrl(URL.createObjectURL(file));
        }
        if (name === 'agentId') {
          setAgentFile(value[0]);
          setAgentPreviewUrl(URL.createObjectURL(file));
        }
      }
      // if (value && value.length > 0) {
      //   const selectedFile = value[0];
      //   const reader = new FileReader();
      //   setFile(selectedFile);
      //   reader.onloadend = () => {
      //     setPreviewUrl(reader.result);
      //     console.log(reader.result);
      //   };
      //   if (selectedFile.type.startsWith('image/')) {
      //     reader.readAsDataURL(selectedFile);
      //   } else if (selectedFile.type === 'application/pdf') {
      //     reader.readAsArrayBuffer(selectedFile);
      //   } else {
      //     setPreviewUrl('');
      //   }
      // }
    } else {
      value = e.target.value;
    }

    setKycData({...kycData, [name]: value});
  };

  const getPersonalKycStatus = async () => {
    try {
      const resp = await customFetch.get('/users/kyc?type=PERSONAL');
      setKyc(resp?.data?.data);
    } catch (error: any) {
      ktoast.error(error?.response?.data?.errors[0]?.message);
    }
  };
  const getAgentKycStatus = async () => {
    try {
      const agentResp = await customFetch.get('/users/kyc?type=AGENT_LICENCE');
      setAgentKyc(agentResp?.data?.data);
    } catch (error: any) {
      ktoast.error(error?.response?.data?.errors[0]?.message);
    }
  };

  const handleAgentKycSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const {agentId, agentIdNumber}: any = kycData;

    const formData = new FormData();
    formData.append('type', 'AGENT_LICENCE');
    formData.append('documentType', 'LICENCE_ID');
    agentIdNumber && formData.append('documentID', agentIdNumber);
    agentId && formData.append('photo', agentId[0]);

    try {
      const res = await customFetch.post(`/users/kyc`, formData);

      if (res.status === 200) {
        setKycData({...kycData, agentId: '', agentIdNumber: ''});
        getAgentKycStatus();
      }

      ktoast.success('Agent Information Added Successfully');
      setLoading(false);
    } catch (error: any) {
      ktoast.error(error?.response?.data?.errors[0]?.message);

      setLoading(false);
    }
  };

  const handleKycSubmit = async (e: any) => {
    e.preventDefault();
    const {
      photo,
      documentID,
      documentType,
      expirationDate,
    }: // certificate,
    // licenseID,
    any = kycData;

    const formData = new FormData();
    setLoading(true);
    // kycType && formData.append('type', kycType);
    formData.append('type', 'PERSONAL');
    photo && formData.append('photo', photo[0]);
    documentID && formData.append('documentID', documentID);
    documentType && formData.append('documentType', documentType);
    expirationDate && formData.append('expirationDate', expirationDate);

    try {
      const res = await customFetch.post(`/users/kyc`, formData);

      if (res.status === 200) {
        setKycData({
          photo: '',
          documentID: '',
          documentType: '',
          expirationDate: '',
          agentId: '',
          agentIdNumber: '',
        });
        getPersonalKycStatus();
      }

      ktoast.success('Identification Added Successfully');
      setLoading(false);
    } catch (error: any) {
      ktoast.error(error?.response?.data?.errors[0]?.message);

      setLoading(false);
    }
  };

  useEffect(() => {
    getPersonalKycStatus();
    if (user?.role === 'agent') {
      getAgentKycStatus();
    }
  }, [user]);
  useEffect(() => {
    let abortController = new AbortController();
    getUserNotifications(user?.id, abortController.signal)
      .then((res: BaseApiResponse<NotificationsInterfaceResponse[]>) => {
        setNotifications(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  // if (bankLoading) {
  //   return <h4>Loading...</h4>;
  // }

  return (
    <DashboardLayout>
      <div className='row mb-3'>
        <h3 className='fw-bold mb-1'>Settings</h3>
        <p className='text-secondary fs-14'>Manage your account on Krent</p>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card border-0 border-round shadow-sm d-flex'>
            <div className='card-body pt-5' style={{paddingLeft: '.8rem'}}>
              {/* Left Sidebar (visible on small screens) */}
              <div className='row'>
                <div className='col-12  d-md-none py-5 ps-0 pe-5 border-end'>
                  <div className='d-flex align-items-start'>
                    <div
                      className='nav flex-column nav-pills me-3 nav-theme-vertical'
                      id='v-pills-tab'
                      role='tablist'
                      aria-orientation='vertical'
                    >
                      {/* <button
                        className='nav-link text-start text-dark mb-4 rounded-0 ps-5 active'
                        id='v-pills-home-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#v-pills-home'
                        type='button'
                        role='tab'
                        aria-controls='v-pills-home'
                        aria-selected='true'
                      >
                        Payments
                      </button> */}
                      {/* Navigation links for settings sections */}
                      <button
                        className='nav-link text-start text-dark mb-4 rounded-0 ps-5 active'
                        id='v-pills-home-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#v-pills-home'
                        type='button'
                        role='tab'
                        aria-controls='v-pills-home'
                        aria-selected='true'
                      >
                        Notifications
                      </button>
                      <button
                        className='nav-link text-start text-dark mb-4 rounded-0 ps-5 '
                        id='v-pills-disabled-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#v-pills-disabled'
                        type='button'
                        role='tab'
                        aria-controls='v-pills-disabled'
                        aria-selected='false'
                      >
                        Login &amp; Security
                      </button>
                      {(user.role === 'landlord' || user.role === 'agent') && (
                        <button
                          className='nav-link text-start text-dark mb-4 rounded-0 ps-5 '
                          id='v-pills-pin-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#v-pills-pin'
                          type='button'
                          role='tab'
                          aria-controls='v-pills-pin'
                          aria-selected='false'
                        >
                          Transaction Pin
                        </button>
                      )}
                      <button
                        className='nav-link text-start text-dark mb-4 rounded-0 ps-5 '
                        id='v-pills-messages-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#v-pills-messages'
                        type='button'
                        role='tab'
                        aria-controls='v-pills-messages'
                        aria-selected='false'
                      >
                        Preferences
                      </button>
                      <button
                        className='nav-link text-start text-dark mb-4 rounded-0 ps-5 '
                        id='v-pills-settings-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#v-pills-settings'
                        type='button'
                        role='tab'
                        aria-controls='v-pills-settings'
                        aria-selected='false'
                      >
                        {/* Privacy &amp; Sharing */}
                        KYC
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* End of Left Sidebar (visible on small screens) */}
              <div className='row'>
                {/* Right Content Section */}
                <div className='col-3 d-none d-md-inline-block py-5 ps-0 pe-5 border-end'>
                  <div className='d-flex align-items-start'>
                    <div
                      className='nav flex-column nav-pills me-3 nav-theme-vertical'
                      id='v-pills-tab'
                      role='tablist'
                      aria-orientation='vertical'
                    >
                      {/* Navigation links for settings sections */}
                      <button
                        className='nav-link text-start text-dark mb-4 rounded-0 ps-5 active'
                        id='v-pills-home-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#v-pills-home'
                        type='button'
                        role='tab'
                        aria-controls='v-pills-home'
                        aria-selected='true'
                      >
                        Notifications
                      </button>
                      <button
                        className='nav-link text-start text-dark mb-4 rounded-0 ps-5 '
                        id='v-pills-disabled-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#v-pills-disabled'
                        type='button'
                        role='tab'
                        aria-controls='v-pills-disabled'
                        aria-selected='false'
                      >
                        Login &amp; Security
                      </button>
                      {/* {(user.role === 'landlord' || user.role === 'agent') && ( */}
                      <button
                        className='nav-link text-start text-dark mb-4 rounded-0 ps-5 '
                        id='v-pills-pin-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#v-pills-pin'
                        type='button'
                        role='tab'
                        aria-controls='v-pills-pin'
                        aria-selected='false'
                      >
                        Transaction Pin
                      </button>
                      {/* )} */}
                      <button
                        className='nav-link text-start text-dark mb-4 rounded-0 ps-5 '
                        id='v-pills-messages-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#v-pills-messages'
                        type='button'
                        role='tab'
                        aria-controls='v-pills-messages'
                        aria-selected='false'
                      >
                        Preferences
                      </button>
                      <button
                        className='nav-link text-start text-dark mb-4 rounded-0 ps-5 '
                        id='v-pills-settings-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#v-pills-settings'
                        type='button'
                        role='tab'
                        aria-controls='v-pills-settings'
                        aria-selected='false'
                      >
                        {/* Privacy &amp; Sharing */}
                        KYC
                      </button>
                    </div>
                  </div>
                </div>
                {/* Content Display */}
                <div className='col-12 col-md-9'>
                  <div className='tab-content' id='v-pills-tabContent'>
                    {/* <div
                      className='tab-pane fade show active'
                      id='v-pills-payment'
                      role='tabpanel'
                      aria-labelledby='v-pills-payment-tab'
                      tabIndex={0}
                    >
                      <div className='container'>
                        <p className='fw-bold fs-20 mb-5'>Payments</p>
                        <form>
                          <div className='row '>
                            <div className='mb-4 col-md-6 col-12'>
                              <label
                                htmlFor='exampleInputEmail1'
                                className='form-label fs-14 fw-600'
                              >
                                Payment method
                              </label>
                              <select
                                className='form-select rounded-pill py-2 px-4'
                                id='exampleInputEmail1'
                                aria-describedby='emailHelp'
                              >
                                <option>Bank</option>
                              </select>
                            </div>
                            <div className='mb-4 col-md-6 col-12'>
                              <label
                                htmlFor='exampleInputEmail1'
                                className='form-label fs-14 fw-600'
                              >
                                Bank name
                              </label>
                              <Select
                                className='rounded-pill px-4 py-2 '
                                id='bankSelect'
                                options={filteredBanks}
                                value={filteredBanks.find(
                                  (bank) => bank.value === bankCode
                                )}
                                onChange={handleBankChange}
                                onInputChange={(inputValue) =>
                                  handleBankSearch(inputValue)
                                }
                                isSearchable
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    padding: '0 1.2rem 0 1.2rem',
                                    borderRadius: '50rem',
                                  }),
                                  // Add more custom styles for other components like menu, option, etc.
                                }}
                              />
                            </div>
                          </div>

                          <div className='row '>
                            <div className='mb-4 col-md-6 col-12'>
                              <label
                                htmlFor='accountNumber'
                                className='form-label fs-14 fw-600'
                              >
                                Account Number
                              </label>
                              <input
                                className='form-control rounded-pill py-2 px-4'
                                type='number'
                                id='accountNumber'
                                value={accountNumber}
                                onChange={handleAccountNumberChange}
                                aria-describedby='emailHelp'
                              />
                            </div>
                            <div className='mb-4 col-md-6 col-12'>
                              <label
                                htmlFor='exampleInputEmail1'
                                className='form-label fs-14 fw-600'
                              >
                                Account Name
                              </label>
                              {accountName && (
                                <p className='form-label fs-14 fw-600'>
                                  {accountName}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className='row'>
                            <div className='mb-4 col-md-6 col-12'>
                              <label
                                htmlFor='exampleInputEmail1'
                                className='form-label fs-14 fw-600'
                              >
                                VAT ID (Optional)
                              </label>
                              <input
                                className='form-control rounded-pill py-2 px-4'
                                id='exampleInputEmail1'
                                aria-describedby='emailHelp'
                              />
                            </div>
                          </div>

                          <button
                            type='button'
                            className='btn btn-outline-dark rounded-pill py-3 px-5 fw-bold fs-14'
                            onClick={handleVerifyAccount}
                          >
                            Verify
                          </button>
                          <button
                            type='submit'
                            className='btn btn-outline-dark rounded-pill py-3 px-5 fw-bold ml-3 fs-14'
                          >
                            Save
                          </button>
                        </form>
                      </div>
                    </div> */}
                    <div
                      className='tab-pane fade show active'
                      id='v-pills-home'
                      role='tabpanel'
                      aria-labelledby='v-pills-home-tab'
                      tabIndex={0}
                    >
                      <div
                        className='card-body border-0-last overflow-auto scrollbar-custom'
                        style={{height: 350}}
                      >
                        {notifications.length > 0 ? (
                          notifications.map((notification) => (
                            <NotificationCard
                              key={notification.id}
                              {...notification}
                            />
                          ))
                        ) : (
                          <div className='col text-center p-3'>
                            <p className='fs-18 fw-bolder'>
                              No notifications yet
                            </p>
                            <img
                              className='py-1'
                              src={emptyNotifications}
                              alt='No Notifications'
                              width={150}
                            />
                            <p className='fs-14 text-secondary text-break'>
                              {' '}
                              Any notification you receive would show up here.
                              You don’t have any requests right now
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Login & Security Tab */}
                    <div
                      className='tab-pane fade'
                      id='v-pills-disabled'
                      role='tabpanel'
                      aria-labelledby='v-pills-disabled-tab'
                      tabIndex={0}
                    >
                      <div className='container'>
                        <p className='fw-bold fs-20 mb-5'>
                          Login &amp; Security
                        </p>
                        <form className='mb-5' onSubmit={handlePasswordChange}>
                          <div className='row'>
                            <div className='mb-4 col-md-6 col-12'>
                              <label className='form-label fs-14 fw-600'>
                                Old Password
                              </label>
                              <input
                                placeholder='Enter old password'
                                name='password'
                                type='password'
                                className='form-control rounded-pill py-3 px-4 fs-14'
                                required
                              />
                            </div>
                            <div className='mb-4 col-md-6 col-12'>
                              <label className='form-label fs-14 fw-600'>
                                New Password
                              </label>
                              <input
                                placeholder='Enter new password'
                                name='newPassword'
                                type='password'
                                className='form-control rounded-pill py-3 px-4 fs-14'
                                required
                                onFocus={() => setPasswordMatchError(false)}
                              />
                              {passwordMatchError && (
                                <div
                                  id='emailHelp'
                                  className='form-text text-danger'
                                >
                                  Passwords do not match
                                </div>
                              )}
                            </div>
                          </div>

                          <div className='row mb-4'>
                            <div className='mb-4 col-md-6 col-12'>
                              <label className='form-label fs-14 fw-600'>
                                Confirm Password
                              </label>
                              <input
                                placeholder='Confirm your new password'
                                name='conPassword'
                                type='password'
                                className='form-control rounded-pill py-3 px-4 fs-14'
                                required
                              />
                              {passwordMatchError && (
                                <div
                                  id='emailHelp'
                                  className='form-text text-danger'
                                >
                                  Passwords do not match
                                </div>
                              )}
                            </div>
                          </div>

                          <button
                            type='submit'
                            className='btn btn-outline-dark rounded-pill py-3 px-5 fw-bold fs-14'
                            disabled={loading}
                          >
                            {loading ? (
                              <span
                                className='spinner-border spinner-border-sm'
                                role='status'
                                aria-hidden='true'
                              ></span>
                            ) : (
                              'Save '
                            )}
                          </button>
                        </form>

                        <p className='fw-bolder fs-14 mb-2'>
                          Deactivate Account
                        </p>
                        <p className='text-secondary fs-12'>
                          Remove your account from Krent permanently{' '}
                          <span className='text-underline text-danger'>
                            Deactivate
                          </span>
                        </p>
                      </div>
                    </div>
                    {/* Transaction Pin Tab */}
                    <div
                      className='tab-pane fade'
                      id='v-pills-pin'
                      role='tabpanel'
                      aria-labelledby='v-pills-pin-tab'
                      tabIndex={0}
                    >
                      {user.hasPIN ? (
                        <div className='container'>
                          <p className='fw-bold fs-20 mb-5'>
                            Reset Transaction Pin
                          </p>
                          <form className='mb-5' onSubmit={handlePinChange}>
                            <div className='row'>
                              <div className='mb-4 col-md-6 col-12'>
                                <label className='form-label fs-14 fw-600'>
                                  Old Pin
                                </label>
                                <input
                                  placeholder='Enter old pin'
                                  name='pin'
                                  type='password'
                                  maxLength={4}
                                  className='form-control rounded-pill py-3 px-4 fs-14'
                                  required
                                />
                              </div>
                              <div className='mb-4 col-md-6 col-12'>
                                <label className='form-label fs-14 fw-600'>
                                  New Pin
                                </label>
                                <input
                                  placeholder='Enter new pin'
                                  name='newPin'
                                  type='password'
                                  maxLength={4}
                                  className='form-control rounded-pill py-3 px-4 fs-14'
                                  required
                                  onFocus={() => setPinMatchError(false)}
                                />
                                {passwordMatchError && (
                                  <div
                                    id='emailHelp'
                                    className='form-text text-danger'
                                  >
                                    Pin do not match
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className='row mb-4'>
                              <div className='mb-4 col-md-6 col-12'>
                                <label className='form-label fs-14 fw-600'>
                                  Confirm Pin
                                </label>
                                <input
                                  placeholder='Confirm your new pin'
                                  name='conPin'
                                  type='password'
                                  maxLength={4}
                                  className='form-control rounded-pill py-3 px-4 fs-14'
                                  required
                                />
                                {pinMatchError && (
                                  <div
                                    id='emailHelp'
                                    className='form-text text-danger'
                                  >
                                    Pin do not match
                                  </div>
                                )}
                              </div>
                            </div>

                            <button
                              type='submit'
                              className='btn btn-outline-dark rounded-pill py-3 px-5 fw-bold fs-14'
                              disabled={loading}
                            >
                              {loading ? (
                                <span
                                  className='spinner-border spinner-border-sm'
                                  role='status'
                                  aria-hidden='true'
                                ></span>
                              ) : (
                                'Save '
                              )}
                            </button>
                          </form>
                        </div>
                      ) : (
                        <div className='container'>
                          <p className='fw-bold fs-20 mb-5'>
                            Create Transaction Pin
                          </p>
                          <form className='mb-5' onSubmit={handleCreatePin}>
                            <div className='row'>
                              {/* <div className='mb-4 col-md-6 col-12'>
                                <label className='form-label fs-14 fw-600'>
                                  Old Pin
                                </label>
                                <input
                                  placeholder='Enter old pin'
                                  name='pin'
                                  type='password'
                                  className='form-control rounded-pill py-3 px-4 fs-14'
                                  required
                                />
                              </div> */}
                              <div className='mb-4 col-md-6 col-12'>
                                <label className='form-label fs-14 fw-600'>
                                  Pin
                                </label>
                                <input
                                  placeholder='Enter new pin'
                                  name='newPin'
                                  type='password'
                                  maxLength={4}
                                  className='form-control rounded-pill py-3 px-4 fs-14'
                                  required
                                  onFocus={() => setPinMatchError(false)}
                                />
                                {passwordMatchError && (
                                  <div
                                    id='emailHelp'
                                    className='form-text text-danger'
                                  >
                                    Pin do not match
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className='row mb-4'>
                              <div className='mb-4 col-md-6 col-12'>
                                <label className='form-label fs-14 fw-600'>
                                  Confirm Pin
                                </label>
                                <input
                                  placeholder='Confirm your new pin'
                                  name='conPin'
                                  type='password'
                                  maxLength={4}
                                  className='form-control rounded-pill py-3 px-4 fs-14'
                                  required
                                />
                                {pinMatchError && (
                                  <div
                                    id='emailHelp'
                                    className='form-text text-danger'
                                  >
                                    Pin do not match
                                  </div>
                                )}
                              </div>
                            </div>

                            <button
                              type='submit'
                              className='btn btn-outline-dark rounded-pill py-3 px-5 fw-bold fs-14'
                              disabled={loading}
                            >
                              {loading ? (
                                <span
                                  className='spinner-border spinner-border-sm'
                                  role='status'
                                  aria-hidden='true'
                                ></span>
                              ) : (
                                'Save '
                              )}
                            </button>
                          </form>
                        </div>
                      )}
                    </div>
                    {/* Preference Tab */}
                    <div
                      className='tab-pane fade'
                      id='v-pills-messages'
                      role='tabpanel'
                      aria-labelledby='v-pills-messages-tab'
                      tabIndex={0}
                    >
                      <div className='container'>
                        <p className='fw-bold fs-20 mb-5'>Preferences</p>
                        <form onSubmit={handleSubmitPreference}>
                          <div className='row mb-4'>
                            <div className='mb-4 col-md-6 col-12'>
                              <label
                                htmlFor='language'
                                className='form-label fs-14 fw-600'
                              >
                                Language
                              </label>
                              <select
                                className='form-select rounded-pill py-2 px-4'
                                id='language'
                                aria-describedby='emailHelp'
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                              >
                                <option value=''>Select</option>
                                <option value='english'>English</option>
                                <option value='french'>French</option>
                              </select>
                            </div>
                            <div className='mb-4 col-6'>
                              <label
                                htmlFor='exampleInputEmail1'
                                className='form-label fs-14 fw-600'
                              >
                                Currency
                              </label>
                              <select
                                className='form-select rounded-pill py-2 px-4'
                                id='exampleInputEmail1'
                                aria-describedby='emailHelp'
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                              >
                                <option value=''>Select</option>
                                <option value='NGN'>Naira - NGN</option>
                                <option value='USD'>Dollar - USD</option>
                              </select>
                            </div>
                          </div>

                          <div className='row mb-4'>
                            <div className='mb-4 col-md-6 col-12'>
                              <label
                                htmlFor='exampleInputEmail1'
                                className='form-label fs-14 fw-600'
                              >
                                Timezone
                              </label>
                              <select
                                className='form-select rounded-pill py-2 px-4'
                                id='exampleInputEmail1'
                                aria-describedby='emailHelp'
                                value={timeZone}
                                onChange={(e) => {
                                  setTimeZone(e.target.value);
                                }}
                              >
                                <option value=''>Select</option>
                                <option value='GMT'>GMT +1 Lagos</option>
                                <option value='UTC'>UTC -05:00 New York</option>
                              </select>
                            </div>
                          </div>

                          <button
                            type='submit'
                            className='btn btn-outline-dark rounded-pill py-3 px-5 fw-bold fs-14'
                          >
                            Save Preferences
                          </button>
                        </form>
                      </div>
                    </div>
                    {/* KYC Tab */}
                    <div
                      className='tab-pane fade'
                      id='v-pills-settings'
                      role='tabpanel'
                      aria-labelledby='v-pills-settings-tab'
                      tabIndex={0}
                    >
                      <p className='fw-bold fs-20 mb-5'>
                        Know Your Customer (KYC)
                      </p>

                      {/* <div className='row mb-4'>
                        <div className='mb-4 col-md-6 col-12'>
                          <label
                            htmlFor='language'
                            className='form-label fs-14 fw-600'
                          >
                            KYC Type
                          </label>
                          <select
                            className='form-select rounded-pill py-2 px-4'
                            id='kycType'
                            aria-describedby='kyc'
                            value={kycType}
                            onChange={(e) => setKycType(e.target.value)}
                          >
                            <option value=''>Select</option>
                            <option value='PERSONAL'>Personal</option>
                            <option value='AGENT_LICENCE'>Agent License</option>
                          </select>
                        </div>
                      </div> */}
                      {/* personal Kyc */}
                      {/* {kycType === "PERSONAL" && ( */}
                      <form>
                        <div className='row'>
                          {kyc?.status === 'APPROVED' ? (
                            <h5 className='mb-3 text-success'>
                              Your{' '}
                              {user.role === 'agent' && <span>personal</span>}{' '}
                              Kyc has been approved!
                            </h5>
                          ) : kyc?.status === 'PENDING' ? (
                            <p className='mb-3 text-primary fs-20 '>
                              Your{' '}
                              {user.role === 'agent' && <span>personal</span>}{' '}
                              KYC submission has been successfully received and
                              is currently undergoing review by one of our legal
                              team members.
                            </p>
                          ) : kyc.status === 'REJECTED' ? (
                            <div>
                              <h5>
                                Your{' '}
                                {user.role === 'agent' && <span>personal</span>}{' '}
                                KYC has been rejected for the following reason:{' '}
                              </h5>
                              <p className='text-danger mt-3 fs-20'>
                                {kyc.rejectionReason}
                              </p>
                              <p className='mb-2'>
                                Kindly reupload your Information
                              </p>
                              <PersonalFormInput
                                kycData={kycData}
                                handleKycOnChange={handleKycOnChange}
                                loading={loading}
                                handleKycSubmit={handleKycSubmit}
                                previewUrl={previewUrl}
                                file={file}
                              />
                            </div>
                          ) : (
                            <PersonalFormInput
                              kycData={kycData}
                              handleKycOnChange={handleKycOnChange}
                              loading={loading}
                              handleKycSubmit={handleKycSubmit}
                              previewUrl={previewUrl}
                              file={file}
                            />
                          )}
                          {/* Agent ID */}
                          {user.role === 'agent' && (
                            <h4 className='my-3'>Agent KYC</h4>
                          )}
                          {agentKyc.status === 'PENDING' &&
                          user.role === 'agent' ? (
                            <div className='row col-12 md-col-8'>
                              <p className='mb-3 text-primary fs-20'>
                                Your agent KYC submission has been successfully
                                received and is currently undergoing review by
                                one of our legal team members.
                              </p>
                            </div>
                          ) : agentKyc.status === 'APPROVED' &&
                            user.role === 'agent' ? (
                            <p className='mb-3 text-success fs-20'>
                              Your {user.role === 'agent' && <span>agent</span>}{' '}
                              KYC has been approved!
                            </p>
                          ) : agentKyc.status === 'REJECTED' &&
                            user.role === 'agent' ? (
                            <div>
                              <h5>
                                Your agent KYC has been rejected for the
                                following reason:{' '}
                              </h5>
                              <p className='text-danger mt-3 fs-20'>
                                {agentKyc.rejectionReason}
                              </p>

                              <p className='mb-2'>
                                Kindly reupload your Information
                              </p>
                              <AgentFormInput
                                user={user}
                                handleAgentKycSubmit={handleAgentKycSubmit}
                                kycData={kycData}
                                loading={loading}
                                handleKycOnChange={handleKycOnChange}
                                previewUrl={agentPreviewUrl}
                                file={agentFile}
                              />
                            </div>
                          ) : (
                            // newFunction(user, handleKycOnChange, kycData, loading, handleAgentKycSubmit)
                            <AgentFormInput
                              user={user}
                              handleAgentKycSubmit={handleAgentKycSubmit}
                              kycData={kycData}
                              loading={loading}
                              handleKycOnChange={handleKycOnChange}
                              previewUrl={agentPreviewUrl}
                              file={agentFile}
                            />
                          )}
                        </div>
                      </form>
                      {/* )} */}
                      {/* Agent cert Kyc */}
                      {/* {kycType === "AGENT_LICENCE" && ( */}
                      {/* <form onSubmit={handleKycSubmit}>
                        <div className='row'>
                          <div className='row'>
                            <div className='col-md-6 col-12 mb-4'>
                              <label className='fw-bolder form-label text-capitalize'>
                                Identification Type
                              </label>
                              <select
                                className='form-select rounded-pill py-3 px-4 fs-14 mb-3'
                                value={kycData.documentType}
                                onChange={handleKycOnChange}
                                required
                                name='documentType'
                              >
                                <option value=''>Select</option>
                                <option value='LICENCE_ID'>
                                  Agent License
                                </option>
                                <option value='CAC'>CAC</option>
                              </select>
                            </div>
                            <div className='col-md-6 col-12 mb-4'>
                              <label className='fw-bolder form-label text-capitalize'>
                                Upload ID
                              </label>
                              <div className='input-group mb-3'>
                                <input
                                  type='file'
                                  className='form-control border-end-0 border-rounded-5 py-3 px-4 rounded-pill-start'
                                  placeholder='No file selected'
                                  id='inputGroupFile02'
                                  name='photo'
                                  onChange={handleKycOnChange}
                                />
                                <label
                                  className='input-group-text rounded-pill-end bg-transparent text-link text-decoration-none border-start-0 pe-4'
                                  htmlFor='inputGroupFile02'
                                >
                                  Upload
                                </label>
                              </div>
                            </div>
                            <div className='col-md-6 col-12 mb-4'>
                              <label className='fw-bolder form-label text-capitalize'>
                                ID Number
                              </label>
                              <input
                                type='text'
                                className='form-control rounded-pill py-3 px-4 fs-14 mb-3'
                                value={kycData.documentID}
                                onChange={handleKycOnChange}
                                required
                                name='documentID'
                                placeholder='Input License ID'
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-6 col-12 mb-4'>
                              <label className='fw-bolder form-label text-capitalize'>
                                Upload Certificate
                              </label>
                              <div className='input-group mb-3'>
                                <input
                                  type='file'
                                  className='form-control border-end-0 border-rounded-5 py-3 px-4 rounded-pill-start'
                                  placeholder='No file selected'
                                  id='inputGroupFile02'
                                  name='photo'
                                  onChange={handleKycOnChange}
                                />
                                <label
                                  className='input-group-text rounded-pill-end bg-transparent text-link text-decoration-none border-start-0 pe-4'
                                  htmlFor='inputGroupFile02'
                                >
                                  Upload
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div>
                              <button
                                type='submit'
                                className='btn btn-primary rounded-pill py-3 px-5 fw-bold fs-14'
                                disabled={loading}
                              >
                                {loading ? (
                                  <span
                                    className='spinner-border spinner-border-sm'
                                    role='status'
                                    aria-hidden='true'
                                  ></span>
                                ) : (
                                  'Save'
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form> */}
                      {/* )} */}
                    </div>
                    {/* end kyc */}
                  </div>
                </div>
                {/* end of content display */}
              </div>
              {/* end of right section */}
            </div>
          </div>
          {/* end of card */}
        </div>
      </div>
    </DashboardLayout>
  );
};
