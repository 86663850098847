import {DashboardLayout} from '@layouts/DashboardLayout';
import {firestore} from '@services/firebase.service';
import {
  acceptTransaction,
  cancelTransaction,
  confirmInspectionForTransaction,
  getSinglePropertyTransaction,
  makePayment,
  pickInspectionForTransaction,
  reportIncident,
  updateInspectionStatus,
  updatePropertyAvailability,
  updateRescheduleAvailability,
} from '@services/krent.service';
import ktoast from '@services/toast.service';
import {RequestViewCard} from '@shared/cards';
import {Bubble} from '@shared/controls/Bubble';
import {Modal} from '@shared/controls/Modal';
import {
  isEmpty,
  phoneNumberCheck,
  phoneNumberRequestCheck,
} from '@utils/helpers';
import {differenceInDays} from 'date-fns';
import {
  Timestamp,
  arrayUnion,
  collection,
  doc,
  // limit,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {Modal as NewModal} from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import message from '../../assets/images/message.png';
import {useSessionStorage} from '../../hooks';
import {
  AvailabilityConfirmation,
  BookShorlet,
  Completed,
  CustomerAccepted,
  DocumentSigned,
  DocumentUpload,
  ScheduleInspection,
  TransactionInspected,
} from './transactionFlow';
import {Link, useNavigate, useParams} from 'react-router-dom';
import customFetch from '@services/axio';
import {PrimaryButton} from '@shared/buttons';
import {InputComponent} from '@shared/forms';
import AddBankAccount from 'components/AddBankAccount';
import Tippy from '@tippyjs/react';
import TermsAndConditionData from '@shared/TermsAndConditionData';
import {BankTransfer} from './BankTransfer';
import {RequestDeposit} from 'components/RequestDeposit';
import ExtendBooking from './ExtendBooking';
import RescheduleBooking from './RescheduleBookings';

export const Messaging = () => {
  const scroll = useRef<HTMLDivElement>(null);
  const [user] = useSessionStorage('user', {});
  const [showCaution, setShowCaution] = useSessionStorage('_k', '1', true);
  const [chats, setChats] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [openSummary, setOpenSummary] = useState<boolean>(false);
  const [openTerms, setOpenTerms] = useState<boolean>(false);
  const [openPolicy, setOpenPolicy] = useState<boolean>(false);
  const [meta, setMeta] = useState<any>({});
  const [fromDate, setFromDate] = useState<any>();
  const [toDate, setToDate] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [loading, setLoading] = useState<boolean>(false);
  const [payLoading, setPayLoading] = useState<boolean>(false);
  const [acceptLoading, setAcceptLoading] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<any>({});
  const [members, setMembers] = useState<any[]>([]);
  const [messages, setMessages] = useState<any[]>([]);
  const [chatMessage, setChatMessage] = useState<string>('');
  const [selectedMessage, setSelectedMessage] = useState<any | null>(null);
  const [agree, setAgree] = useState<boolean>(false);
  const [toggleMessage, setToggleMessage] = useState<boolean>(false);
  const [kyc, setKyc] = useState<any>('');
  const [cancellationReason, setCancellationReason] = useState<string>('');
  const [openCancellation, setOpenCancellation] = useState<boolean>(false);
  const [showAddBankModal, setShowAddBankModal] = useState(false);
  const [showRequestDeposit, setShowRequestDeposit] = useState(false);
  const [showExtendBooking, setShowExtendBooking] = useState(false);
  const [showRescheduleBooking, setShowRescheduleBooking] = useState(false);
  const [openIncident, setOpenIncident] = useState(false);
  const [incident, setIncident] = useState('');

  // const [legalDocument, setLegalDocument] = useState<FileList | null>(null);
  // const [legalDocumentUrl, setLegalDocumentUrl] = useState<any>('');

  const datePickerMinDate = new Date();
  const messageCollections: any = process.env['REACT_APP_FIREBASE_COLLECTION'];

  const link = `/properties/?intent=${transaction?.listing?.intent}`;
  const navigate = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    const q = query(
      collection(firestore, messageCollections),
      where('members', 'array-contains', user?.id)
      // limit(25)
    );
    const unsub = onSnapshot(q, (querySnapshot) => {
      const msgs: any[] = [];

      querySnapshot.forEach((doc) => {
        msgs.push({...doc.data(), id: doc.id});
      });

      setChats(msgs);
      if (showCaution === '1') {
        setShowModal(true);
        setShowCaution('0');
      }
    });

    return unsub;
  }, [user, showCaution, setShowCaution]);

  useEffect(() => {
    let unsub = null;
    if (id) {
      setSelectedMessage(id);

      // Mark as read
      const docRef = doc(firestore, messageCollections, id);
      unsub = onSnapshot(docRef, (docSnap) => {
        // console.log(docSnap.data());
        const {
          chats: chatMessages,
          meta,
          members: chatMembers,
        } = docSnap.data() || {chats: [], meta: {}};

        setMessages(chatMessages);
        setMeta(meta);
        setMembers(chatMembers);

        const abortController = new AbortController();
        setLoading(true);
        // getSinglePropertyTransaction(meta?.request?.id, abortController.signal)
        getSinglePropertyTransaction(meta?.request?.id, abortController.signal)
          .then((d: any) => {
            setTransaction(d.data);
          })
          .catch(() => {
            // Failed to load transactions
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }

    if (unsub) {
      return unsub;
    }
  }, [id]);

  useEffect(() => {
    let unsub = null;
    if (selectedMessage) {
      // Mark as read
      const docRef = doc(firestore, messageCollections, selectedMessage);
      unsub = onSnapshot(docRef, (docSnap) => {
        // console.log(docSnap.data());
        const {
          chats: chatMessages,
          meta,
          members: chatMembers,
        } = docSnap.data() || {chats: [], meta: {}};
        setMessages(chatMessages);
        setMeta(meta);
        setMembers(chatMembers);

        const abortController = new AbortController();
        setLoading(true);
        // getSinglePropertyTransaction(meta?.request?.id, abortController.signal)
        getSinglePropertyTransaction(meta?.request?.id, abortController.signal)
          .then((d: any) => {
            setTransaction(d.data);
          })
          .catch(() => {
            // Failed to load transactions
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }

    if (unsub) {
      return unsub;
    }
  }, [selectedMessage]);

  //  Fetch user kyc status. this is using axios
  const getKycStatus = async () => {
    try {
      const resp = await customFetch.get('/users/kyc');
      setKyc(resp?.data?.data);
    } catch (error: any) {
      setKyc(error?.response?.data?.errors[0]?.message);
    }
  };
  useEffect(() => {
    getKycStatus();
  }, []);

  const handlePropertyAvailability = (payload: 'available' | 'unavailable') => {
    setAcceptLoading(true);
    updatePropertyAvailability(meta?.request?.id, payload)
      .then((d: any) => {
        setTransaction(d?.data);
        const docRef = doc(firestore, messageCollections, selectedMessage);
        updateDoc(docRef, {
          chats: arrayUnion({
            from_uid: user?.id,
            to_uid: user?.id === members[0] ? members[1] : members[0],
            type: 'notification',
            value: `${
              d?.data?.property_avalability_status === 'available'
                ? 'Owner has updated listing status.The property is available for booking'
                : 'We are sorry this property is unavailable for booking at the moment.'
            } `,
            similarListingsUrl: `${
              d?.data?.property_avalability_status === 'available' ? '' : link
            }`,
            createdAt: Timestamp.now(),
          }),
          read: false,
          updatedAt: serverTimestamp(),
        })
          .then(() => {
            // Clear message
            // Scroll to bottom
          })
          .catch(() => {
            ktoast.error(
              'Failed to send message. Please check you have a working internet connection'
            );
          });
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setAcceptLoading(false);
      });
  };
  const handleRescheduleAvailability = (
    payload: 'available' | 'unavailable'
  ) => {
    setAcceptLoading(true);
    updateRescheduleAvailability(meta?.request?.id, payload)
      .then((d: any) => {
        setTransaction(d?.data);
        const docRef = doc(firestore, messageCollections, selectedMessage);
        updateDoc(docRef, {
          chats: arrayUnion({
            from_uid: user?.id,
            to_uid: user?.id === members[0] ? members[1] : members[0],
            type: 'notification',
            value: `${
              payload === 'available'
                ? 'The property is available for the date you selected, and we’ve updated your arrival to the new date.'
                : 'We are sorry this property is unavailable for booking for the chosen date.'
            } `,
            similarListingsUrl: `${
              d?.data?.property_avalability_status === 'available' ? '' : link
            }`,
            createdAt: Timestamp.now(),
          }),
          read: false,
          updatedAt: serverTimestamp(),
        })
          .then(() => {
            // Clear message
            // Scroll to bottom
          })
          .catch(() => {
            ktoast.error(
              'Failed to send message. Please check you have a working internet connection'
            );
          });
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setAcceptLoading(false);
      });
  };

  const scheduleBooking = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAcceptLoading(true);
    const docRef = doc(firestore, messageCollections, selectedMessage);
    const payload = {
      option1_from: fromDate?.toISOString(),
      option1_to: toDate?.toISOString(),
      // TODO: Accept range
      option2_from: fromDate?.toISOString(),
      option2_to: toDate?.toISOString(),
    };
    pickInspectionForTransaction(meta?.request?.id, payload)
      .then((d: any) => {
        setTransaction(d.data);
        updateDoc(docRef, {
          chats: arrayUnion({
            from_uid: user?.id,
            to_uid: user?.id === members[0] ? members[1] : members[0],
            type: 'notification',
            value: 'Customer has selected a preffered inspection date',
            createdAt: Timestamp.now(),
          }),
          read: false,
          updatedAt: serverTimestamp(),
        })
          .then(() => {
            // Clear message
            // Scroll to bottom
          })
          .catch(() => {
            ktoast.error(
              'Failed to send message. Please check you have a working internet connection'
            );
          });
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setAcceptLoading(false);
      });
  };

  const confirmBooking = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAcceptLoading(true);
    confirmInspectionForTransaction(meta?.request?.id, selectedDate)
      .then((d: any) => {
        setTransaction(d.data);

        const docRef = doc(firestore, messageCollections, selectedMessage);
        updateDoc(docRef, {
          chats: arrayUnion({
            from_uid: user?.id,
            to_uid: user?.id === members[0] ? members[1] : members[0],
            type: 'notification',
            value: 'Owner has confirmed a preffered inspection date',
            createdAt: Timestamp.now(),
          }),
          read: false,
          updatedAt: serverTimestamp(),
        })
          .then(() => {
            // Clear message
            // Scroll to bottom
          })
          .catch(() => {
            ktoast.error(
              'Failed to send message. Please check you have a working internet connection'
            );
          });
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setAcceptLoading(false);
      });
  };

  const confirmInspection = () => {
    setAcceptLoading(true);
    updateInspectionStatus(meta?.request?.id)
      .then((d: any) => {
        const docRef = doc(firestore, messageCollections, selectedMessage);
        setTransaction(d.data);
        updateDoc(docRef, {
          chats: arrayUnion({
            from_uid: user?.id,
            to_uid: user?.id === members[0] ? members[1] : members[0],
            type: 'notification',
            value: 'Customer has confirmed inspection status',
            createdAt: Timestamp.now(),
          }),
          read: false,
          updatedAt: serverTimestamp(),
        })
          .then(() => {
            // Clear message
            // Scroll to bottom
          })
          .catch(() => {
            ktoast.error(
              'Failed to send message. Please check you have a working internet connection'
            );
          });
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setAcceptLoading(false);
      });
  };
  // const acceptShortletBooking = () => {
  //   setAcceptLoading(true);
  //   updateInspectionStatus(meta?.request?.id)
  //     .then((d: any) => {
  //       setTransaction(d.data);
  //       const docRef = doc(firestore, 'messages', selectedMessage);
  //       acceptTransaction(meta?.request?.id)
  //         .then((d: any) => {
  //           setTransaction(d.data);
  //           updateDoc(docRef, {
  //             chats: arrayUnion({
  //               from_uid: user?.id,
  //               to_uid: user?.id === members[0] ? members[1] : members[0],
  //               type: 'notification',
  //               value: 'Customer has agreed to proceed with transaction.',
  //               createdAt: Timestamp.now(),
  //             }),
  //             updatedAt: serverTimestamp(),
  //           })
  //             .then(() => {
  //               // Clear message
  //               // Scroll to bottom
  //             })
  //             .catch(() => {
  //               ktoast.error(
  //                 'Failed to send message. Please check you have a working internet connection'
  //               );
  //             });
  //         })
  //         .catch((err) => {
  //           ktoast.error(err.errors[0].message);
  //         })
  //         .finally(() => {
  //           setAcceptLoading(false);
  //         });
  //     })
  //     .catch((err) => {
  //       ktoast.error(err.errors[0].message);
  //     })
  //     .finally(() => {
  //       setAcceptLoading(false);
  //     });
  // };

  const acceptBooking = () => {
    setAcceptLoading(true);
    const docRef = doc(firestore, messageCollections, selectedMessage);
    acceptTransaction(meta?.request?.id)
      .then((d: any) => {
        setTransaction(d.data);
        updateDoc(docRef, {
          chats: arrayUnion({
            from_uid: user?.id,
            to_uid: user?.id === members[0] ? members[1] : members[0],
            type: 'notification',
            value: 'Customer has agreed to proceed with transaction.',
            createdAt: Timestamp.now(),
          }),
          read: false,
          updatedAt: serverTimestamp(),
        })
          .then(() => {
            // Clear message
            // Scroll to bottom
          })
          .catch(() => {
            ktoast.error(
              'Failed to send message. Please check you have a working internet connection'
            );
          });
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setAcceptLoading(false);
      });
  };
  const cancelBooking = (e: any) => {
    e.preventDefault();
    setOpenCancellation(false);
    setAcceptLoading(true);
    const docRef = doc(firestore, messageCollections, selectedMessage);
    cancelTransaction(meta?.request?.id, cancellationReason)
      .then((d: any) => {
        setTransaction(d.data);
        updateDoc(docRef, {
          chats: arrayUnion({
            from_uid: user?.id,
            to_uid: user?.id === members[0] ? members[1] : members[0],
            type: 'notification',
            value: 'Customer has cancelled this transaction.',
            createdAt: Timestamp.now(),
            similarListingsUrl: link,
          }),
          read: false,
          updatedAt: serverTimestamp(),
        })
          .then(() => {
            // Clear message
            // Scroll to bottom
          })
          .catch(() => {
            ktoast.error(
              'Failed to send message. Please check you have a working internet connection'
            );
          });
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setAcceptLoading(false);
      });
  };
  const handleSubmitIncident = (e: any) => {
    e.preventDefault();
    setLoading(true);
    reportIncident(meta?.request?.id, incident)
      .then((resp: any) => {
        ktoast.success(resp?.message);
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectMessage = (chatId: any) => {
    const docRef = doc(firestore, messageCollections, chatId);

    setSelectedMessage(chatId);

    updateDoc(docRef, {
      read: true,
    })
      .then(() => {})
      .catch(() => {});
  };

  const handleSendMessage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const docRef = doc(firestore, messageCollections, selectedMessage);
    // let msg: any;
    // if (phoneNumberRegex.test(msg)) {
    //   msg = 'Sending phone numbers is not allowed';
    //   alert('Sending phone numbers is not allowed.');
    //   return;
    // }

    updateDoc(docRef, {
      chats: arrayUnion({
        from_uid: user?.id,
        to_uid: user?.id === members[0] ? members[1] : members[0],
        type: 'text',
        value: phoneNumberCheck.test(chatMessage)
          ? 'Phone number detected. Sharing phone numbers is not permitted.'
          : phoneNumberRequestCheck.test(chatMessage)
          ? 'Requesting phone numbers is not allowed. Please contact Krent Customer Care for assistance.'
          : chatMessage,
        createdAt: Timestamp.now(),
      }),
      updatedAt: serverTimestamp(),
      read: false,
    })
      .then(() => {
        setChatMessage('');
        scroll?.current?.scrollIntoView({behavior: 'smooth'});
        // Clear message
        // Scroll to bottom
      })
      .catch(() => {
        ktoast.error(
          'Failed to send message. Please check you have a working internet connection'
        );
      });
  };

  const payNow = () => {
    setPayLoading(true);
    makePayment(transaction.id, {
      email: transaction?.renter?.email,
      amount: parseFloat(transaction?.bill[0]?.total),
    })
      .then((res: any) => {
        localStorage.setItem(
          'paymentRef',
          JSON.stringify({ref: res.reference, id: transaction.id})
        );
        window.location.href = res.authorization_url;
      })
      .catch((err) => {
        const error = err.errors;
        error && console.log(error[0]?.message);
      })
      .finally(() => setPayLoading(false));
  };

  // function to control bank account modal
  const handleCloseAccountModal = () => {
    setShowAddBankModal(false);
  };
  // function to control request Deposit
  const handleCloseRequestDepositModal = () => {
    setShowRequestDeposit(false);
  };
  // function to control renew booking
  const handleCloseExtendBooking = () => {
    setShowExtendBooking(false);
  };
  // function to control reschedule
  const handleCloseRescheduleBooking = () => {
    setShowRescheduleBooking(false);
  };

  // const handleLegalDoc = (e: any) => {
  //   const value = e.target.files;

  //   setLegalDocument(value);
  //   const [file] = value;
  //   setLegalDocumentUrl(URL.createObjectURL(file));
  // };

  // const handleLegalDocSubmission = (e: any) => {
  //   e.preventDefault();

  //   const formData = new FormData();

  //   for (let i = 0; i < legalDocument?.length!; i++) {
  //     formData.append('file', legalDocument![i]);
  //   }
  //   uploadLegalDoc(transaction.id, formData)
  //     .then((resp: any) => {
  //       const url = resp.envelopeSendingResults;
  //       const docRef = doc(firestore, 'messages', selectedMessage);
  //       updateDoc(docRef, {
  //         chats: arrayUnion({
  //           from_uid: user?.id,
  //           to_uid: user?.id === members[0] ? members[1] : members[0],
  //           type: 'notification',
  //           value: `Owner has uploaded a legal agreement document. Kindly follow the link to read and sign the document. The link as also be sent to your registered email address`,
  //           documentUrl: url,
  //           createdAt: Timestamp.now(),
  //         }),
  //         updatedAt: serverTimestamp(),
  //       })
  //         .then(() => {
  //           // Clear message
  //           // Scroll to bottom
  //         })
  //         .catch(() => {
  //           ktoast.error(
  //             'Failed to send message. Please check you have a working internet connection'
  //           );
  //         });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  return (
    <DashboardLayout>
      <div className='row vh-100 relative' style={{overflow: 'scroll'}}>
        {/* side message bar */}

        <div
          className='col-12 col-md-3 p-4 p-md-0 d-none d-md-block'
          style={{
            maxHeight: 'calc(100vh - 150px)',
            overflowY: 'scroll',
          }}
        >
          <h4 className='fw-bold mb-3'>Messaging</h4>
          {/* <input
            type='text'
            className='form-control border-round-xl bg-purple px-4 py-2'
            placeholder='Search for someone...'
          /> */}

          <div className='col-12 mt-4'>
            {chats
              .sort(
                (a, b) =>
                  (b.updatedAt?.seconds || b.createdAt?.seconds) -
                  (a.updatedAt?.seconds || a.createdAt?.seconds)
              )
              .map((chat) => {
                const isUnread =
                  user.id !== chat.chats[chat.chats.length - 1].from_uid &&
                  !chat.read;
                const isSelected = chat.id === selectedMessage;
                const senderAvatar =
                  user.role === 'user'
                    ? 'https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg'
                    : chat?.meta?.sent_by?.photoUrl === 'default.jpg'
                    ? 'https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg'
                    : `${process.env['REACT_APP_ASSET_BASE_URL']}/users/${chat?.meta?.sent_by?.photoUrl}`;

                return (
                  <div key={chat.id} className='position-relative'>
                    {isUnread && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '10px',
                          right: '10px',
                        }}
                      >
                        <span
                          className='badge bg-success'
                          style={{
                            fontSize: '12px',
                            fontWeight: 'bold',
                            padding: '4px 8px',
                          }}
                        >
                          New
                        </span>
                      </div>
                    )}
                    <div
                      className={`row py-3 mb-2 btn-el  rounded-2 ${
                        isSelected ? 'bg-primary' : 'bg-primary-light'
                      }`}
                      style={{
                        // backgroundColor: isSelected ? '#007869' : '#cce7e3',
                        border: isSelected ? '1px solid #007869' : 'none',
                        maxWidth: '95%',
                        margin: '0 auto',
                      }}
                      onClick={() => handleSelectMessage(chat.id)}
                    >
                      <div className='col-3 col-md-3'>
                        <img
                          src={senderAvatar}
                          className='rounded-circle ml-1'
                          alt=''
                          width={50}
                        />
                      </div>
                      <div className='col-9'>
                        <h6
                          className={`m-0 fw-bold text-capitalize ${
                            isSelected ? 'textLight' : 'textDark'
                          }`}
                        >
                          {user.role === 'user'
                            ? chat.meta?.owner?.name
                            : chat?.meta?.sent_by?.name?.toLowerCase()}
                        </h6>
                        <p
                          className={`m-0  fs-14 ${
                            isSelected ? 'textLight' : 'textDark'
                          }`}
                        >
                          {chat?.chats[chat.chats.length - 1]?.value}
                        </p>
                        <div
                          className={`d-flex justify-content-between mt-2 ${
                            isSelected ? 'textLight' : 'textDark'
                          }`}
                        >
                          <p className='m-0  fs-12'>
                            {chat?.updatedAt?.toDate().toLocaleDateString() ||
                              chat?.createdAt?.toDate().toLocaleDateString()}
                          </p>

                          <p className='m-0  fs-12'>
                            {chat?.updatedAt
                              ?.toDate()
                              .toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                              }) ||
                              chat?.createdAt
                                ?.toDate()
                                .toLocaleTimeString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                })}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {/*Mobile side message bar */}
        <div
          className={` d-md-none ${
            toggleMessage ? 'hideMessage' : 'displayMessage'
          }`}
          style={{
            // maxHeight: 'calc(100vh - 150px)',
            overflow: 'scroll',
            background: '#fff',
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            width: '100%',
            height: '100%',
            zIndex: '999',
          }}
        >
          <div
            className='col-12  col-md-3 mt-5 pt-4  p-md-0 d-md-none'
            style={{
              // maxHeight: 'calc(100vh - 150px)',
              overflow: 'scroll',
            }}
          >
            <h4 className='fw-bold mb-3 mt-3'>Messaging</h4>
            {/* <input
              type='text'
              className='form-control border-round-xl bg-purple px-4 py-2'
              placeholder='Search for someone...'
            /> */}

            <div className='col-12 mt-4'>
              {chats
                .sort(
                  (a, b) =>
                    (b.updatedAt?.seconds || b.createdAt?.seconds) -
                    (a.updatedAt?.seconds || a.createdAt?.seconds)
                )
                .map((chat) => {
                  const isUnread =
                    user.id !== chat.chats[chat.chats.length - 1].from_uid &&
                    !chat.read;
                  const isSelected = chat.id === selectedMessage;
                  const senderAvatar =
                    user.role === 'user'
                      ? 'https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg'
                      : chat?.meta?.sent_by?.photoUrl === 'default.jpg'
                      ? 'https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg'
                      : `${process.env['REACT_APP_ASSET_BASE_URL']}/users/${chat?.meta?.sent_by?.photoUrl}`;

                  return (
                    <div key={chat.id} className='position-relative'>
                      {isUnread && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                          }}
                        >
                          <span
                            className='badge bg-success'
                            style={{
                              fontSize: '12px',
                              fontWeight: 'bold',
                              padding: '4px 8px',
                            }}
                          >
                            New
                          </span>
                        </div>
                      )}
                      <div
                        className={`row py-3 mb-2 btn-el  rounded-2 ${
                          isSelected ? 'bg-primary' : 'bg-primary-light'
                        }`}
                        style={{
                          // backgroundColor: isSelected ? '#daf1ee' : '#edf8f6',
                          border: isSelected ? '1px solid #daf1ee' : 'none',
                          maxWidth: '95%',
                          margin: '0 auto',
                        }}
                        onClick={() => {
                          handleSelectMessage(chat?.id);
                          setToggleMessage((prev) => !prev);
                        }}
                      >
                        <div className='col-3 col-md-3'>
                          <img
                            src={senderAvatar}
                            className='rounded-circle ml-1'
                            alt=''
                            width={50}
                          />
                        </div>
                        <div className='col-9 col-md-6'>
                          <h6
                            className={`m-0 fw-bold text-capitalize ${
                              isSelected ? 'textLight' : 'textDark'
                            }`}
                          >
                            {user.role === 'user'
                              ? chat.meta?.owner?.name
                              : chat?.meta?.sent_by?.name?.toLowerCase()}
                          </h6>
                          <p
                            className={`m-0  fs-14 ${
                              isSelected ? 'textLight' : 'textDark'
                            }`}
                          >
                            {chat?.chats[chat.chats.length - 1]?.value}
                          </p>
                          <div
                            className={`d-flex gap-3 ${
                              isSelected ? 'textLight' : 'textDark'
                            }`}
                          >
                            <p className='m-0  fs-12 '>
                              {chat?.updatedAt?.toDate().toLocaleDateString() ||
                                chat?.createdAt?.toDate().toLocaleDateString()}
                            </p>
                            <p className='m-0  fs-12'>
                              {chat?.updatedAt
                                ?.toDate()
                                .toLocaleTimeString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                }) ||
                                chat?.createdAt
                                  ?.toDate()
                                  .toLocaleTimeString('en-US', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                  })}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* Message Display */}
        <div className='col-12 col-md-9 '>
          {selectedMessage ? (
            // <div className='row d-none d-md-flex'>
            <div className=' d-md-flex '>
              <div
                // className='col-8 '
                className='col-12 mb-5 d-none col-md-8 d-md-block'
                style={{minHeight: 'calc(100vh - 150px)'}}
              >
                <div className='col-12 py-2 text-center rounded-pill bg-primary bg-opacity-10 mb-3'>
                  <p className='fs-14 text-secondary text-capitalize mb-0'>
                    {meta?.sent_by?.name?.toLowerCase()} started a conversation
                  </p>
                </div>

                <div
                  className='container overflow-auto scrollbar-custom'
                  style={{
                    minHeight: 'calc(100% - 50px)',
                    maxHeight: 'calc(100vh - 500px)',
                  }}
                >
                  {messages &&
                    messages.length > 0 &&
                    messages.map((d, i) => {
                      return (
                        <Bubble
                          key={i}
                          avatar={d?.photoUrl}
                          timestamp={d?.createdAt
                            ?.toDate()
                            .toLocaleTimeString('en-Us', {
                              hour: 'numeric',
                              minute: 'numeric',
                            })}
                          text={d?.value}
                          direction={
                            user?.id === d?.from_uid ? 'sender' : 'receiver'
                          }
                          similarListingsUrl={d?.similarListingsUrl}
                          link={d?.documentUrl ? d.documentUrl : ''}
                          status={transaction?.status}
                        />
                      );
                    })}
                  <div ref={scroll}></div>
                </div>

                <form className='px-4' onSubmit={handleSendMessage}>
                  <div className='input-group mb-3'>
                    <input
                      type='text'
                      disabled={
                        loading ||
                        (transaction &&
                          transaction?.property_avalability_status ===
                            'pending')
                      }
                      value={chatMessage}
                      onChange={(e) => setChatMessage(e.target.value)}
                      className='form-control border-round-xl px-4 py-3 fs-12'
                      placeholder='Enter a message'
                      style={{outline: 'none !important'}}
                    />
                    <button className='btn btn-primary' type='submit'>
                      <i className='fas fa-paper-plane'></i>
                    </button>
                  </div>
                </form>
              </div>
              <div
                // className='col-4'
                className='col-12 mt-5 col-md-4 mt-md-0 px-4'
                style={{minHeight: '100%', height: '100%'}}
              >
                {/* Back for mobile */}
                <span
                  className='d-md-none cursor-pointer position-absolute mt-5 ml-3 fixed-top'
                  onClick={() => setToggleMessage((prev) => !prev)}
                >
                  <i className='fa fas fa-chevron-left'></i>
                </span>
                <div className='text-center'>
                  <img
                    src={
                      meta?.sent_by?.photoUrl === 'default.jpg'
                        ? 'https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg'
                        : `${process.env['REACT_APP_ASSET_BASE_URL']}/users/${meta?.sent_by?.photoUrl}`
                    }
                    alt=''
                    width={100}
                    className='rounded-circle'
                  />

                  <h6 className='fw-bold my-2'>{meta?.sent_by?.name}</h6>
                </div>
                <div className='text-start mt-5 mb-4'>
                  <h6 className='fs-12 fw-bold m-0'>REQUEST TYPE</h6>
                  <p className='fs-14 m-0'>{transaction?.listing?.intent}</p>
                </div>
                {transaction?.listing?.intent !== 'sale' &&
                  transaction &&
                  Object.keys(transaction).length !== 0 && (
                    <div className='text-start mb-4'>
                      <h6 className='fs-12 fw-bold m-0'>DURATION</h6>
                      <p className='fs-14 m-0'>
                        {new Date(
                          transaction?.arrival_date?.toLocaleString()
                        ).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                        {' - '}
                        {new Date(
                          transaction?.departure_date?.toLocaleString()
                        ).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                        {' ('}
                        {differenceInDays(
                          new Date(transaction?.departure_date),
                          new Date(transaction?.arrival_date)
                        )}{' '}
                        {transaction.listing.intent === 'shortlet'
                          ? ' night)'
                          : ' days)'}
                      </p>
                    </div>
                  )}
                {/* Reschedule date */}
                {transaction?.requested_arrival_date &&
                  transaction?.owner.id === user.id &&
                  transaction?.arrival_date !==
                    transaction?.requested_arrival_date && (
                    <div className='text-start mb-4 alert alert-success'>
                      <h6 className='fs-12 fw-bold m-0'>Reschedule Date</h6>
                      <p className='fs-14 m-0'>
                        {new Date(
                          transaction?.requested_arrival_date?.toLocaleString()
                        ).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                        {' - '}
                        {new Date(
                          transaction?.requested_departure_date?.toLocaleString()
                        ).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                        {' ('}
                        {differenceInDays(
                          new Date(transaction?.requested_departure_date),
                          new Date(transaction?.requested_arrival_date)
                        )}{' '}
                        {transaction.listing.intent === 'shortlet'
                          ? ' night)'
                          : ' days)'}
                      </p>
                      {acceptLoading ? (
                        <span className='spinner-border'></span>
                      ) : (
                        <div className='d-flex gap-3 align-items-center'>
                          <span
                            className='py-1 px-2 fw-600  rounded-pill mt-2 d-inline-block  cursor-pointer'
                            onClick={() =>
                              handleRescheduleAvailability('available')
                            }
                          >
                            Accept
                          </span>
                          <span
                            className='py-1 px-2 fw-600 text-danger rounded-pill mt-2 d-inline-block cursor-pointer'
                            onClick={() =>
                              handleRescheduleAvailability('unavailable')
                            }
                          >
                            Unavailable
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                <Fragment>
                  {loading ? (
                    <span className='spinner-border'></span>
                  ) : transaction &&
                    transaction?.property_avalability_status === 'pending' ? (
                    // function to render pending status
                    AvailabilityConfirmation(
                      transaction,
                      user,
                      acceptLoading,
                      handlePropertyAvailability
                    )
                  ) : transaction &&
                    transaction?.property_avalability_status === 'available' &&
                    transaction.status === 'progress' &&
                    transaction.listing.intent === 'shortlet' ? (
                    transaction.renter?.id === user?.id && (
                      // function to render available status
                      <BookShorlet
                        setOpenTerms={setOpenTerms}
                        setOpenPolicy={setOpenPolicy}
                        agree={agree}
                        setAgree={setAgree}
                        acceptLoading={acceptLoading}
                        acceptBooking={acceptBooking}
                        kyc={kyc}
                        setOpenCancellation={() => setOpenCancellation(true)}
                      />
                    )
                  ) : transaction?.status === 'customer-accepted' &&
                    transaction.listing.intent === 'shortlet' ? (
                    // function to render customer-accepted status
                    <Fragment>
                      {transaction.renter?.id === user?.id ? (
                        <Fragment>
                          <div className='mb-3'>
                            <i className='iconly-Paper align-text-bottom icli fs-22 me-1'></i>

                            <span
                              className='fs-14 text-link'
                              onClick={() => setOpenSummary(true)}
                            >
                              Summary
                            </span>
                          </div>
                          <div className='alert alert-warning' role='alert'>
                            <p className='fs-14'>
                              Please proceed to make payment
                            </p>

                            <div className='d-flex justify-content-between mb-2'>
                              <span className='fs-13 fw-bold'>
                                Property Price:
                              </span>
                              <span className='fs-13'>
                                {transaction?.bill[0]?.propertyPrice?.toLocaleString()}
                              </span>
                            </div>

                            <div className='d-flex justify-content-between mb-2'>
                              <span className='fs-13 fw-bold'>
                                Refundable Caution Fee:
                              </span>

                              <span className='fs-13'>
                                {transaction?.bill[0]?.cautionFee?.toLocaleString()}
                              </span>
                            </div>

                            {/* { transaction.listing?.intent === 'long lease' &&  <div className='d-flex justify-content-between mb-2'>
                              <span className='fs-14 fw-bold'>
                                Krent Admin Charges:
                              </span>
                              <span className='fs-14'>
                                {transaction?.bill[0]?.krentAdminCharge?.toLocaleString()}
                              </span>
                            </div>} */}

                            {/* <div className='d-flex justify-content-between mb-4'>
                              <span className='fs-14 fw-bold'>Tax:</span>
                              <span className='fs-14'>
                                {transaction?.bill[0]?.tax?.toLocaleString()}
                              </span>
                            </div> */}

                            <div className='d-flex justify-content-between mb-2'>
                              <span className='fs-13 fw-bold'>Total:</span>
                              <span className='fs-13'>
                                {transaction?.bill[0]?.total?.toLocaleString()}
                              </span>
                            </div>
                          </div>
                          <div>
                            <BankTransfer transaction={transaction} />
                            <div className='mt-2'>
                              <button
                                className='btn btn-primary w-100'
                                onClick={payNow}
                              >
                                {payLoading ? (
                                  <span
                                    className='spinner-border spinner-border-sm'
                                    role='status'
                                    aria-hidden='true'
                                  ></span>
                                ) : (
                                  'Pay with Paystack'
                                )}
                              </button>
                              <div className=' ml-3'>
                                <i className='fa-solid fa-lock fs-8'></i>
                                <span className='fs-8 ml-1'>
                                  Payment Secured by{' '}
                                  <strong className='text-primary'>
                                    Paystack
                                  </strong>{' '}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      ) : (
                        <div className='alert alert-primary' role='alert'>
                          <p className='fs-14'>
                            Krent is currently awaiting payment from customer.
                          </p>
                        </div>
                      )}
                    </Fragment>
                  ) : transaction?.status === 'completed' &&
                    transaction?.listing?.intent === 'shortlet' ? (
                    <Fragment>
                      {transaction.renter?.id === user?.id ? (
                        <>
                          {/* Manage Booking */}
                          <div className='dropdown mb-3 w-100'>
                            <button
                              className='btn bg-primary text-white  dropdown-toggle w-100'
                              type='button'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              Manage your booking
                            </button>
                            <ul className='dropdown-menu'>
                              <li>
                                <p
                                  className='fs-14 cursor-pointer dropdown-item'
                                  onClick={() => setShowExtendBooking(true)}
                                >
                                  Extend/Renew Booking
                                </p>
                              </li>
                              <li>
                                <p
                                  className='fs-14 cursor-pointer m-0 dropdown-item'
                                  onClick={() => setShowRescheduleBooking(true)}
                                >
                                  Reschedule Booking
                                </p>
                              </li>
                              <li>
                                <p
                                  className='fs-14 cursor-pointer m-0 dropdown-item'
                                  onClick={() => setOpenCancellation(true)}
                                >
                                  Cancel Booking
                                </p>
                              </li>
                            </ul>
                          </div>

                          {/* End Manage Booking */}
                          <div className='alert alert-success' role='alert'>
                            <p className='fs-14'>
                              Your payment was successful.
                            </p>
                          </div>
                          <div className='alert alert-secondary' role='alert'>
                            <p className='fs-14'>The Host as been notified.</p>
                            <p className='fs-14'>
                              Kindly Set up your wallet{' '}
                              <Link to='/wallet'>Here</Link> for your caution
                              fee refund.
                            </p>
                            <p className='fs-14'>
                              To Setup your wallet Kindly create a transaction
                              pin <Link to='/settings'>Here</Link>{' '}
                            </p>
                          </div>
                          {transaction?.reason && (
                            <div className='row'>
                              <div className='col-12'>
                                <div
                                  role='alert'
                                  className='alert alert-warning alert-dismissible fade show'
                                >
                                  Your Cancellation request is being processed
                                </div>
                              </div>
                            </div>
                          )}
                          {/* Manage Booking */}
                          {/* <div className='dropdown'>
                            <button
                              className='btn btn-primary dropdown-toggle'
                              type='button'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              Manage your booking
                            </button>
                            <ul className='dropdown-menu'>
                              <li>
                                <p
                                  className='fs-14 cursor-pointer dropdown-item'
                                  onClick={() => setShowExtendBooking(true)}
                                >
                                  Extend/Renew Booking
                                </p>
                              </li>
                              <li>
                                <p
                                  className='fs-14 cursor-pointer m-0 dropdown-item'
                                  onClick={() => setShowRescheduleBooking(true)}
                                >
                                  Reschedule Booking
                                </p>
                              </li>
                              <li>
                                <p
                                  className='fs-14 cursor-pointer m-0 dropdown-item'
                                  onClick={() => setOpenCancellation(true)}
                                >
                                  Cancel Booking
                                </p>
                              </li>
                            </ul>
                          </div> */}
                        </>
                      ) : (
                        <div className='mb-3 '>
                          <div className='alert alert-success' role='alert'>
                            <p className='fs-14'>Payment has been completed.</p>
                          </div>

                          <div className='alert alert-primary' role='alert'>
                            <p className='fs-14'>
                              Please ensure all necessary preparations are in
                              place to ensure your guest enjoys a wonderful and
                              unforgettable experience at your facility.
                            </p>
                          </div>
                          <div className='d-md-flex gap-3'>
                            {!transaction?.listing?.landlordAccountDetails && (
                              <div>
                                {' '}
                                <h6 className='fs-10 fw-bold m-0'>
                                  Landlord's Account Details
                                </h6>
                                <button
                                  className='btn btn-primary'
                                  onClick={() => setShowAddBankModal(true)}
                                >
                                  Add Now
                                </button>
                                <Tippy content='Due to your affiliation as an agent with this property,we will therefore transfer the due amount to the landlord and remit your commission to your account.'>
                                  <p className='fs-12 mt-1 ml-1 text-primary'>
                                    why am i doing this?
                                  </p>
                                </Tippy>
                              </div>
                            )}
                            <div>
                              {' '}
                              <h6 className='fs-10 fw-bold m-0'>
                                Request Payment Deposit
                              </h6>
                              <button
                                className='btn btn-primary'
                                onClick={() => setShowRequestDeposit(true)}
                              >
                                Request
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ) : transaction?.status === 'cancelled' &&
                    transaction?.listing?.intent === 'shortlet' ? (
                    <Fragment>
                      <div className='alert alert-warning' role='alert'>
                        <p className='fs-14'>Transaction has been cancelled.</p>
                      </div>
                    </Fragment> //    &&
                  ) : //   !transaction?.agreementDocumentId ? (
                  //   // function to render completed status
                  //   Completed(transaction, user)
                  // ) : transaction.status === 'completed' &&
                  //   transaction?.agreementDocumentId &&
                  //   !transaction.agreementSigned ? (
                  //   // function to render document upload

                  //   DocumentUpload(transaction, user)
                  // ) : transaction.status === 'completed' &&
                  //   transaction?.agreementDocumentId &&
                  //   transaction?.agreementSigned ? (
                  //   // function to render signed document link
                  //   DocumentSigned(transaction, user)
                  // )
                  // ) : // Start for Rental and Sales
                  transaction &&
                    transaction?.property_avalability_status === 'available' &&
                    transaction.listing.intent !== 'shortlet' ? (
                    <Fragment>
                      {transaction && transaction?.status ? (
                        transaction?.status === 'progress' ||
                        transaction?.status === 'awaiting-inspection' ? (
                          transaction?.inspection_schedules &&
                          !isEmpty(transaction?.inspection_schedules) ? (
                            transaction?.inspection_date ? (
                              new Date(
                                transaction?.inspection_date
                              ).getTime() <= new Date().getTime() ? (
                                transaction?.renter?.id === user?.id && (
                                  <Fragment>
                                    <p className='fs-14'>
                                      Inspected the property? Please confirm
                                      only if you've successfully inspected the
                                      property.
                                    </p>
                                    <div className='d-grid'>
                                      <button
                                        className='btn btn-primary rounded-pill px-4 py-3'
                                        disabled={acceptLoading}
                                        onClick={confirmInspection}
                                      >
                                        {acceptLoading ? (
                                          <span className='spinner-border spinner-border-sm'></span>
                                        ) : (
                                          'Yes, I have'
                                        )}
                                      </button>
                                      <button className='btn btn-link fs-12'>
                                        Report a problem
                                      </button>
                                    </div>
                                  </Fragment>
                                )
                              ) : (
                                <div
                                  className='alert alert-primary'
                                  role='alert'
                                >
                                  <p className='fs-14'>
                                    Inspection Date has been scheduled for{' '}
                                    {new Date(
                                      transaction?.inspection_date?.toLocaleString()
                                    ).toLocaleTimeString('en-US', {
                                      year: 'numeric',
                                      month: 'short',
                                      day: 'numeric',
                                    })}{' '}
                                  </p>
                                </div>
                              )
                            ) : (
                              transaction?.owner?.id === user?.id && (
                                <form
                                  className='mt-2'
                                  onSubmit={confirmBooking}
                                >
                                  <p className='fs-14'>
                                    Please select a preferred date between&nbsp;
                                    {new Date(
                                      transaction?.inspection_schedules?.option1?.from?.toLocaleString()
                                    ).toLocaleTimeString('en-US', {
                                      year: 'numeric',
                                      month: 'short',
                                      day: 'numeric',
                                    })}{' '}
                                    {/* to{' '}
                                    {
                                      transaction?.inspection_schedules?.option1
                                        ?.to
                                    } */}
                                    <span className='fw-bold'> AND </span>
                                    {/* {
                                      transaction?.inspection_schedules?.option2
                                        ?.from
                                    }{' '}
                                    to{' '} */}
                                    {new Date(
                                      transaction?.inspection_schedules?.option2?.to?.toLocaleString()
                                    ).toLocaleTimeString('en-US', {
                                      year: 'numeric',
                                      month: 'short',
                                      day: 'numeric',
                                    })}{' '}
                                  </p>

                                  <div className='form-group mb-3'>
                                    <div className='input-group'>
                                      <span className='input-group-text input-group-text-0'>
                                        <i
                                          className={`iconly-Calendar icli fs-4`}
                                        ></i>
                                      </span>

                                      <div className='form-control form-control-0   border-start-0'>
                                        <DatePicker
                                          selected={selectedDate}
                                          onChange={(date: any) =>
                                            setSelectedDate(date)
                                          }
                                          placeholderText='dd-mm-yyyy'
                                          minDate={datePickerMinDate}
                                          className='form-control form-control-0  border-0'
                                          showYearDropdown
                                          showMonthDropdown
                                          showTimeSelect
                                          dateFormat='dd-MM-yyyy'
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className='d-grid'>
                                    <button
                                      className='btn btn-primary rounded-pill px-4 py-3'
                                      disabled={acceptLoading}
                                    >
                                      {acceptLoading ? (
                                        <span className='spinner-border spinner-border-sm'></span>
                                      ) : (
                                        'Confirm'
                                      )}
                                    </button>
                                  </div>
                                </form>
                              )
                            )
                          ) : (
                            transaction.renter?.id === user?.id &&
                            ScheduleInspection(
                              scheduleBooking,
                              fromDate,
                              setFromDate,
                              datePickerMinDate,
                              toDate,
                              setToDate,
                              acceptLoading,
                              acceptBooking
                            )
                          )
                        ) : transaction?.status === 'inspected' ? (
                          transaction.renter?.id === user?.id &&
                          TransactionInspected(
                            setOpenTerms,
                            setOpenPolicy,
                            agree,
                            setAgree,
                            acceptLoading,
                            acceptBooking
                          )
                        ) : transaction?.status === 'customer-accepted' ? (
                          // function to render customer accepted status
                          <CustomerAccepted
                            setOpenSummary={setOpenSummary}
                            user={user}
                            transaction={transaction}
                            payNow={payNow}
                            payLoading={payLoading}
                            kyc={kyc}
                          />
                        ) : transaction?.status === 'completed' &&
                          !transaction?.agreementDocumentId ? (
                          // function to render completed status
                          <Completed
                            transaction={transaction}
                            user={user}
                            setShowAddBankModal={setShowAddBankModal}
                            setOpenIncident={setOpenIncident}
                          />
                        ) : transaction.status === 'completed' &&
                          transaction?.agreementDocumentId &&
                          !transaction.agreementSigned ? (
                          // function to render document uploaded

                          DocumentUpload(
                            transaction,
                            user,
                            setShowAddBankModal,
                            setOpenIncident
                          )
                        ) : transaction.status === 'completed' &&
                          transaction?.agreementDocumentId &&
                          transaction?.agreementSigned ? (
                          // function to render signed document link
                          DocumentSigned(
                            transaction,
                            user,
                            setShowExtendBooking,
                            setOpenIncident
                          )
                        ) : (
                          <p>Unknown</p>
                        )
                      ) : (
                        <p>Something else</p>
                      )}
                    </Fragment>
                  ) : (
                    <div>
                      <p>Property is unavailable</p>
                      {transaction.renter?.id === user?.id && (
                        <Link to={`${link}`}>Browse similar listings</Link>
                      )}
                    </div>
                  )}
                </Fragment>
              </div>

              {/* for mobile display */}
              <div
                // className='col-8 '
                className='p-4 col-12 mb-5 col-md-8 mb-md-0 d-md-none '
                style={{minHeight: 'calc(100vh - 150px)'}}
              >
                <div className='col-12 py-2 text-center rounded-pill bg-primary bg-opacity-10 mb-3'>
                  <p className='fs-14 text-secondary text-capitalize mb-0'>
                    {meta?.sent_by?.name?.toLowerCase()} started a conversation
                  </p>
                </div>

                <div
                  className='container overflow-auto scrollbar-custom'
                  style={{
                    minHeight: 'calc(100% - 50px)',
                    maxHeight: 'calc(100vh - 500px)',
                  }}
                >
                  {messages &&
                    messages.length > 0 &&
                    messages.map((d, i) => {
                      return (
                        <Bubble
                          key={i}
                          avatar={d?.photoUrl}
                          timestamp={d?.createdAt
                            ?.toDate()
                            .toLocaleTimeString('en-Us', {
                              hour: 'numeric',
                              minute: 'numeric',
                            })}
                          text={d?.value}
                          direction={
                            user?.id === d?.from_uid ? 'sender' : 'receiver'
                          }
                        />
                      );
                    })}
                  <div ref={scroll}></div>
                </div>

                <form className='px-4' onSubmit={handleSendMessage}>
                  <div className='input-group mb-3'>
                    <input
                      type='text'
                      disabled={
                        loading ||
                        (transaction &&
                          transaction?.property_avalability_status ===
                            'pending')
                      }
                      value={chatMessage}
                      onChange={(e) => setChatMessage(e.target.value)}
                      className='form-control border-round-xl px-4 py-3 fs-12'
                      placeholder='Enter a message'
                      style={{outline: 'none !important'}}
                    />
                    <button className='btn btn-primary' type='submit'>
                      <i className='fas fa-paper-plane'></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div className='d-flex flex-column align-items-center justify-content-center text-center h-100'>
              <p className='fw-bold mb-4'>No conversation selected</p>
              <img src={message} alt='' width={200} />
              <p className='light-text fs-14 w-75 mt-4'>
                You haven’t selected any message yet. Select a conversation to
                continue messaging
              </p>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        dismissible={false}
      >
        <div className='container py-3'>
          <p className='fw-bold'>Before your conversation</p>
          <p className='fs-14'>
            Please ensure that you don not make any payments or set up any
            meetings outside the platform. Krent will not take responsibility
            for issues that may arise if you do so
          </p>

          <button
            data-bs-dismiss='modal'
            className='btn btn-primary rounded-pill px-5 py-3 text-white fw-bold mt-3'
          >
            I understand
          </button>
          <button
            data-bs-dismiss='modal'
            className='btn btn-primary rounded-pill px-5 py-3 text-white fw-bold mt-3 ml-3'
            onClick={() => {
              navigate(`${user.role === 'user' ? '/explore/' : '/overview/'}`);
            }}
          >
            Back
          </button>
        </div>
      </Modal>
      <NewModal
        center
        open={openSummary}
        onClose={() => setOpenSummary(false)}
        classNames={{
          // overlayAnimationIn: 'customEnterOverlayAnimation',
          // overlayAnimationOut: 'customLeaveOverlayAnimation',
          modalAnimationIn: 'customEnterModalAnimation',
          modalAnimationOut: 'customLeaveModalAnimation',
          modal: ' rounded',
        }}
        animationDuration={800}
        aria-labelledby='summary-title'
        aria-describedby='summary-description'
      >
        <RequestViewCard req={transaction} summary />
      </NewModal>
      <NewModal
        open={openTerms}
        center
        onClose={() => setOpenTerms(false)}
        // classNames={{ modal: 'w-300 rounded' }}
        classNames={{
          // overlayAnimationIn: 'customEnterOverlayAnimation',
          // overlayAnimationOut: 'customLeaveOverlayAnimation',
          modalAnimationIn: 'customEnterModalAnimation',
          modalAnimationOut: 'customLeaveModalAnimation',
        }}
        animationDuration={800}
      >
        <TermsAndConditionData />
      </NewModal>
      <NewModal
        open={openPolicy}
        center
        onClose={() => setOpenPolicy(false)}
        // classNames={{ modal: 'w-300 rounded' }}
        classNames={{
          // overlayAnimationIn: 'customEnterOverlayAnimation',
          // overlayAnimationOut: 'customLeaveOverlayAnimation',
          modalAnimationIn: 'customEnterModalAnimation',
          modalAnimationOut: 'customLeaveModalAnimation',
        }}
        animationDuration={800}
      >
        <div className='col-10 mt-3'>
          <div className='col-12'>
            <h6 className='fs-12 fw-bold h-modal-color'>CANCELLATION POLICY</h6>
            <p className='fs-14 light-text' id='summary-description'>
              Before you book , make sure you are comfortable with this Hosts
              cancellation policy.
            </p>
          </div>
        </div>
      </NewModal>
      <NewModal
        open={openCancellation}
        center
        onClose={() => setOpenCancellation(false)}
        // classNames={{ modal: 'w-300 rounded' }}
        classNames={{
          // overlayAnimationIn: 'customEnterOverlayAnimation',
          // overlayAnimationOut: 'customLeaveOverlayAnimation',
          modalAnimationIn: 'customEnterModalAnimation',
          modalAnimationOut: 'customLeaveModalAnimation',
        }}
        animationDuration={800}
      >
        <div className='col-10 mt-3'>
          <div className='col-12'>
            <h6 className='fs-12 fw-bold h-modal-color'>
              Confirm Booking Cancellation
            </h6>
            <p className='fs-14 light-text' id='summary-description'>
              We are sorry you have to cancel this booking. In other to serve
              you better please share with us the reason why you are cancelling
              this transaction.
            </p>
            <form className='mt-4 mt-md-3' onSubmit={cancelBooking}>
              <div className='col mb-4'>
                <InputComponent
                  label=''
                  icon=''
                  placeholder='Please state your reason'
                  type='text'
                  value={cancellationReason}
                  change={(e: any) => setCancellationReason(e.target.value)}
                  required
                />
              </div>

              <div className='col text-center'>
                <PrimaryButton type='primary' text='Submit' loading={loading} />
              </div>
            </form>
          </div>
        </div>
      </NewModal>
      {/* Add bank account modal */}
      <Modal
        id='addBank'
        show={showAddBankModal}
        onClose={handleCloseAccountModal}
      >
        <AddBankAccount
          id={user?.wallet}
          type='landlord'
          propertyId={transaction?.listing?.id}
        />
      </Modal>
      <Modal
        id='requestDeposit'
        show={showRequestDeposit}
        onClose={handleCloseRequestDepositModal}
      >
        <RequestDeposit
          walletId={transaction?.owner?.wallet}
          transactionId={transaction?.id}
        />
      </Modal>
      <Modal
        id='extendBooking'
        show={showExtendBooking}
        onClose={handleCloseExtendBooking}
      >
        <ExtendBooking
          transactionId={transaction?.id}
          listingId={transaction?.listing?.id}
          type={transaction?.listing?.intent}
          owner={transaction?.owner?.id}
          ownerName={transaction?.owner?.preferredName}
          price={transaction?.listing?.price}
          currency='NGN'
          duration={
            transaction?.listing?.intent === 'shortlet' ? 'daily' : 'yearly'
          }
          members={members}
          selectedMessage={selectedMessage}
          setTransaction={setTransaction}
        />
      </Modal>
      <Modal
        id='rescheduleBooking'
        show={showRescheduleBooking}
        onClose={handleCloseRescheduleBooking}
      >
        <RescheduleBooking
          transactionId={transaction?.id}
          listingId={transaction?.listing?.id}
          type={transaction?.listing?.intent}
          owner={transaction?.owner?.id}
          ownerName={transaction?.owner?.preferredName}
          price={transaction?.listing?.price}
          currency='NGN'
          duration={
            transaction?.listing?.intent === 'shortlet' ? 'daily' : 'yearly'
          }
          members={members}
          selectedMessage={selectedMessage}
          setTransaction={setTransaction}
        />
      </Modal>
      <NewModal
        open={openIncident}
        center
        onClose={() => setOpenIncident(false)}
        // classNames={{ modal: 'w-300 rounded' }}
        classNames={{
          // overlayAnimationIn: 'customEnterOverlayAnimation',
          // overlayAnimationOut: 'customLeaveOverlayAnimation',
          modalAnimationIn: 'customEnterModalAnimation',
          modalAnimationOut: 'customLeaveModalAnimation',
        }}
        animationDuration={800}
      >
        <div className='col-10 mt-3'>
          <div className='col-12'>
            <h6 className='fs-12 fw-bold h-modal-color'>Report an Incident</h6>
            <p className='fs-14 light-text' id='summary-description'>
              If you have any complaint or encounter any challenges with the
              Agent or Landlord please report it we will be happy to help
              resolve it.
            </p>
            <form className='mt-4 mt-md-3' onSubmit={handleSubmitIncident}>
              <div className='col mb-4'>
                {/* <InputComponent
                  label=''
                  icon=''
                  placeholder='Please state your complaint'
                  type='text'
                  value={incident}
                  change={(e: any) => setIncident(e.target.value)}
                  required
                /> */}
                <textarea
                  name='incident'
                  id='incident'
                  placeholder='Please state your complaint'
                  value={incident}
                  onChange={(e: any) => setIncident(e.target.value)}
                  required
                  className='w-100 p-3'
                  rows={5}
                ></textarea>
              </div>

              <div className='col text-center'>
                <PrimaryButton type='primary' text='Submit' loading={loading} />
              </div>
            </form>
          </div>
        </div>
      </NewModal>
      {/* Bank Transfer Modal */}
      {/* <Modal
        id='bankTransfer'
        show={openBankTransfer}
        onClose={() => setOpenBankTransfer(false)}
      >
        <div>
          <h4>Bank Transfer Details</h4>

          <div className=''>
            <span className='fw-bold'>Bank Name</span> 
            <p></p>
          </div>
        </div>
      </Modal> */}
    </DashboardLayout>
  );
};
