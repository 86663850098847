import {Helmet} from 'react-helmet';
import {capitalizedWord} from './helper';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const GetMeta = ({
  type,
  bedroom,
  kind,
  location,
  total,
  description = 'Find Shortlet, Houses, Flats & Land for Sale in Nigeria on Krent.',
  title,
  canonicalValue,
}: any) => {
  const paramLocation = useLocation();
  const url = process.env['REACT_APP_API_BASE_URL'];

  const capitalizeTitle = (str: string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    const head = document.head;
    const titleElement = document.querySelector('title');
    const metaDescription = document.querySelector('meta[name="description"]');

    // Move the title to the top of the <head>
    if (titleElement) {
      head.insertBefore(titleElement, head.firstChild);
    }

    // Insert the meta description immediately after the title
    if (metaDescription) {
      // Check if titleElement is still the first child after moving
      // This helps ensure the description follows it directly
      if (head.firstChild === titleElement) {
        head.insertBefore(metaDescription, titleElement!.nextSibling);
      } else {
        head.insertBefore(metaDescription, head.firstChild);
      }
    }
  }, [total, type, description, paramLocation.pathname]); // Re-run this effect if title or description changes
  // Dependencies array includes title and description

  // useEffect(() => {
  //   const titleElement = document.querySelector('title');
  //   // Select all description meta tags
  //   const metaDescriptions = document.querySelectorAll(
  //     'meta[name="description"]'
  //   );
  //   const head = document.head;
  //   if (titleElement) {
  //     head.insertBefore(titleElement, head.firstChild);
  //   }
  //   // Move the last meta description (which should be the Helmet one) to the top
  //   if (metaDescriptions.length > 1) {
  //     head.insertBefore(
  //       metaDescriptions[metaDescriptions.length - 1],
  //       titleElement ? titleElement.nextSibling : head.firstChild
  //     );
  //   }
  // }, [type, total]);

  if (type === 'sale') {
    let text = `${total} House, Flats & Land For Sale in Nigeria | Krent`;
    let canonical = `property-for-sale`;
    if (bedroom) {
      text = `${total} ${bedroom} property for sale in Nigeria`;
    }

    if (kind) {
      text = `${total} ${
        kind === 'land'
          ? 'Land for Sale in Nigeria | Krent'
          : kind === 'apartment'
          ? 'Flats & Apartment for Sale in Nigeria | Krent'
          : kind === 'commercial'
          ? 'Commercial Property For Sale in Nigeria | Krent'
          : kind === 'house'
          ? 'house for sale in Nigeria | Krent'
          : `${kind} property for sale in Nigeria | Krent`
      } `;
      canonical = `property-for-sale/${kind}`;
    }
    if (location) {
      text = `${total} Property for sale in ${capitalizedWord(location)}`;
      canonical = `property-for-sale/${location}`;
    }
    if (kind && location) {
      text = `${total} ${kind}${
        total > 1 ? 's' : ''
      } for sale in ${capitalizedWord(location)}`;
      canonical = `property-for-sale/${kind}/in/${location}`;
    }

    return (
      <Helmet>
        <title>{capitalizeTitle(text)}</title>
        <meta
          name='description'
          content={`${
            kind === 'land'
              ? `Find Plots, Acres & Hectares of Land for Sale in ${
                  location ? capitalizedWord(location) : 'Nigeria'
                }   on Krent`
              : kind === 'apartment'
              ? `Find Flats & Apartments For Sale in ${
                  location ? capitalizedWord(location) : 'Nigeria'
                }  on Krent`
              : kind === 'commercial'
              ? `Find Offices, Shops, Hotels,School, Warehouses, Filling Stations & Other Commercial Property for Sale in ${
                  location ? capitalizedWord(location) : 'Nigeria'
                }  on Krent.`
              : `Find Houses, Flats & Land for Sale in ${
                  location ? capitalizedWord(location) : 'Nigeria'
                }  on Krent. Search by price, location, property size, number of bedrooms, and more for a tailored property search experience.`
          }`}
        />
        <link
          rel='canonical'
          href={`${
            url?.includes('staging')
              ? 'https://staging.krent.space'
              : 'https://krent.space'
          }${canonical}`}
        />
      </Helmet>
    );
  }

  if (type === 'rent' || type === 'long lease') {
    let text = `${total} House, Flats & Land For Rent in Nigeria | Krent`;
    let canonical = `property-for-rent`;
    if (bedroom) {
      text = `${total} ${bedroom} property for rent in Nigeria`;
    }
    if (kind) {
      text = `${total} ${
        kind === 'land'
          ? 'Land for Rent in Nigeria | Krent'
          : kind === 'apartment'
          ? 'Flats & Apartment for Rent in Nigeria | Krent'
          : kind === 'commercial'
          ? 'Offices, Stores, Warehouses & Others for Rent in Nigeria | Krent'
          : kind
      } property for Rent in Nigeria`;
      canonical = `property-for-rent/${kind}`;
    }
    if (location) {
      text = `${total} Property for rent in ${capitalizedWord(location)}`;
      canonical = `property-for-sale/in/${location}`;
    }
    if (kind && location) {
      text = `${total} ${kind}${
        total > 1 ? 's' : ''
      } for rent in ${capitalizedWord(location)}`;
      canonical = `property-for-rent/${kind}/in/${location}`;
    }
    return (
      <Helmet>
        <title>{capitalizeTitle(text)}</title>
        <meta
          name='description'
          content={`Find Houses, Flats, Lands & Commercial Property for rent in ${
            location ? capitalizedWord(location) : 'Nigeria'
          }  on Krent. Real Estate to Let in Nigeria.`}
        />
        <link
          rel='canonical'
          href={`${
            url?.includes('staging')
              ? 'https://staging.krent.space'
              : 'https://krent.space'
          }${canonical}`}
        />
      </Helmet>
    );
  }
  if (type === 'shortlet') {
    let text = `${total} House, Flats & Apartment For Shortlet in Nigeria | Krent`;
    let canonical = `property-for-shortlet`;
    if (bedroom) {
      text = `${total} ${bedroom} shortlet property in Nigeria`;
    }
    if (kind) {
      text = `${total} ${capitalizedWord(kind)} shortlet property in Nigeria`;
      canonical = `property-for-shortlet/${kind}`;
    }
    if (location) {
      text = `${total} Houses, Flats & Apartments For Short let in ${location}, Nigeria | Krent`;
      canonical = `property-for-shortlet/in/${location}`;
    }
    return (
      <Helmet>
        <title>{capitalizeTitle(text)}</title>
        <meta
          name='description'
          content={`Find Houses, Flats & Apartments For Shortlet in ${location} Nigeria on Krent. Real Estate to Let in Nigeria.`}
        />
        <link
          rel='canonical'
          href={`${
            url?.includes('staging')
              ? 'https://staging.krent.space'
              : 'https://krent.space'
          }${canonical}`}
        />
      </Helmet>
    );
  }

  if (canonicalValue) {
    return (
      <Helmet>
        <title>
          {title
            ? title
            : 'Real Estate & Property in Nigeria for Sale and Rent - Krent'}
        </title>
        <meta
          name='description'
          content={
            description
              ? description
              : 'Krent is a real estate and property listing website in Nigeria. Find homes to buy or rent from trusted landlords and agents. Sell your property or find a new home easily with our wide selection of flats, apartments, houses, and more'
          }
        />
        <link
          rel='canonical'
          href={`${
            url?.includes('staging')
              ? 'https://staging.krent.space'
              : 'https://krent.space'
          }${canonicalValue}`}
        />
      </Helmet>
    );
  }

  return (
    <Helmet>
      <title>
        {title
          ? title
          : 'Real Estate & Property in Nigeria for Sale and Rent - Krent'}
      </title>
      <meta
        name='description'
        content={
          description
            ? description
            : 'Krent is a real estate and property listing website in Nigeria. Find homes to buy or rent from trusted landlords and agents. Sell your property or find a new home easily with our wide selection of flats, apartments, houses, and more'
        }
      />
    </Helmet>
  );
};

export default GetMeta;
