import {BaseApiResponse, PaginatedResponse} from '@interfaces/app.interface';
import {PropertyKind} from '@interfaces/properties.interface';
import //  useJsApiLoader
'@react-google-maps/api';
import {getPropertyKinds} from '@services/krent.service';
import {formatCurrency} from '@utils/currency';
import {addPageToSessionStorage} from '@utils/helpers';
import {useEffect, useState} from 'react';
import {createSearchParams, useLocation, useNavigate} from 'react-router-dom';

export const FiltersCard = ({
  autoCompleteLocation,
  setAutoCompleteLocation,
  page,
  limit,
  setPage,
  setFiltersLoaded,
}: any) => {
  // const {isLoaded} = useJsApiLoader({
  //   googleMapsApiKey: process.env['REACT_APP_GOOGLE_API_KEY'] || '',
  //   libraries: ['places'],
  // });

  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(0);
  const [minSize, setMinSize] = useState<number>(0);
  const [maxSize, setMaxSize] = useState<number>(0);
  const [propertyType, setPropertyType] = useState<string>('');
  const [propertyKinds, setPropertyKinds] = useState<PropertyKind[]>([]);

  const [propertyLocation, setPropertyLocation] = useState<string>('');
  // const [autoCompleteLocation, setAutoCompleteLocation] = useState<string>('');
  const [bedroom, setBedroom] = useState<string>('');
  const [bed, setBed] = useState<string>('');
  const [bathroom, setBathroom] = useState<string>('');
  // const [page, setPage] = useState<any>(1);
  // const limit: any = 20;

  const navigate = useNavigate();
  const location: any = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const initialIntent = urlParams.get('intent');
  const [intent, setIntent] = useState<string | any>(initialIntent || '');

  const caps = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // const [autoCompleteState, setAutoCompleteState] = useState<any>(null);
  // const onLoadState = (autocomplete: any) => {
  //   setAutoCompleteState(autocomplete);
  //   console.log('map loaded');
  // };
  // const onStateChanged = () => {
  //   console.log('Changed');

  //   if (autoCompleteState !== null) {
  //     const state =
  //       autoCompleteState
  //         .getPlace()
  //         .address_components?.find((p: any) =>
  //           p.types.includes('sublocality_level_1')
  //         )?.long_name ||
  //       autoCompleteState
  //         .getPlace()
  //         .address_components?.find((p: any) =>
  //           p.types.includes('neighborhood')
  //         )?.long_name ||
  //       autoCompleteState
  //         .getPlace()
  //         .address_components?.find((p: any) => p.types.includes('locality'))
  //         ?.long_name ||
  //       autoCompleteState
  //         .getPlace()
  //         .address_components?.find((p: any) =>
  //           p.types.includes('administrative_area_level_1')
  //         )?.long_name;

  //     setAutoCompleteLocation(state);

  //     // console.log(autoCompleteState.getPlace().address_components);
  //   } else {
  //     console.log('Autocomplete is not loaded yet!');
  //   }
  // };
  // Handle Search Function
  const onSearch = (): void => {
    let params: URLSearchParams = new URLSearchParams();
    if (bedroom) params.append('bedrooms', bedroom);
    if (bathroom) params.append('bathrooms', bathroom);
    if (bed) params.append('beds', bed);
    if (page) params.append('page', page);
    if (limit) params.append('limit', limit);
    if (intent) params.append('intent', intent);
    if (propertyType) params.append('kind.title', propertyType);
    if (maxPrice) params.append('price[lt]', maxPrice.toString());
    if (minPrice) params.append('price[gt]', minPrice.toString());
    if (maxSize) params.append('size[lte]', maxSize.toString());
    if (minSize) params.append('size[gte]', minSize.toString());
    if (autoCompleteLocation) {
      params.append('location.city', autoCompleteLocation);
    } else if (propertyLocation) {
      params.append('location.city', caps(propertyLocation));
    }

    // Check for existing query parameters in the current URL
    const existingSearchParams = new URLSearchParams(window.location.search);
    const existingParamsArray = Array.from(existingSearchParams.entries());
    for (const [key, value] of existingParamsArray) {
      // If the query parameter is not already set in our params, append it
      if (!params.has(key)) {
        params.append(key, value);
      }
    }

    navigate(
      {
        pathname: '/properties/',
        search: `?${createSearchParams(params)}`,
      },
      {replace: false}
    );
  };

  // const changePage = (page: number) => {
  //   setPage(page);
  // };

  const handleSearch = () => {
    // reset the page back to 1 if filtering
    addPageToSessionStorage(1);
    setFiltersLoaded(true);

    setPage(1);
    onSearch();
    setAutoCompleteLocation('');
    setPropertyLocation('');
    setPropertyType('');
    setMaxPrice(0);
    setMinPrice(0);
    setMaxSize(0);
    setMinSize(0);
    setBathroom('');
    setBedroom('');
    setBed('');
    setIntent('');
  };

  const handleClearFilters = () => {
    setAutoCompleteLocation('');
    setPropertyLocation('');
    setPropertyType('');

    setMaxPrice(0);
    setMinPrice(0);
    setMaxSize(0);
    setMinSize(0);
    setBathroom('');
    setBedroom('');
    setBed('');
    setIntent('');
    setFiltersLoaded(false);
  };

  const onChangeSlider = (
    e: React.ChangeEvent<HTMLInputElement>,
    callback: Function
  ): void => {
    callback(Number(e.target.value));
  };
  useEffect(() => {
    let abortController = new AbortController();
    getPropertyKinds(abortController.signal)
      .then((res: BaseApiResponse<PropertyKind[], PaginatedResponse>) => {
        setPropertyKinds(res.data);
      })
      .catch(() => {})
      .finally(() => {});

    return () => {
      return abortController.abort();
    };
  }, []);

  return (
    <div className='offcanvas-body p-4'>
      {/* location */}
      <div className='row mb-5'>
        <label htmlFor='staticEmail' className='col-sm-6 col-form-label fw-600'>
          Location
        </label>
        <div className='col-sm-6'>
          <input
            type='text'
            className='border-0 fw-bold form-control fs-16'
            value={propertyLocation}
            name='property location'
            onChange={(e) => {
              setPropertyLocation(e.target.value);
            }}
          />
        </div>
      </div>
      {/* Property Size */}
      <div className='row mb-5'>
        <label htmlFor='staticEmail' className='col-sm-6 col-form-label fw-600'>
          Size
        </label>
        <div className='col-sm-6'>
          <div className='dropdown'>
            <button
              type='button'
              className='btn btn-transparent border-0'
              id='sizeDropdown'
              aria-haspopup='true'
              aria-expanded='false'
              data-bs-toggle='dropdown'
            >
              <span className='fw-bold'>
                {minSize}m - {maxSize}m
              </span>
              <i className='iconly-Arrow-Down-Circle icbo ml-2'></i>
            </button>
            <div
              className='dropdown-menu'
              aria-labelledby='dropdown'
              style={{width: 400}}
            >
              <div className='container'>
                <div className='row'>
                  <div className='col-6'>
                    <label htmlFor='customRange1' className='form-label'>
                      Min
                    </label>
                    <input
                      type='range'
                      className='form-range'
                      id='customRange1'
                      max={300}
                      defaultValue={120}
                      onChange={(e) => onChangeSlider(e, setMinSize)}
                    />
                  </div>
                  <div className='col-6'>
                    <label htmlFor='customRange2' className='form-label'>
                      Max
                    </label>
                    <input
                      type='range'
                      className='form-range'
                      id='customRange2'
                      max={5000}
                      defaultValue={150}
                      // step={10000}
                      onChange={(e) => onChangeSlider(e, setMaxSize)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Price */}
      <div className='row mb-5'>
        <label htmlFor='staticEmail' className='col-sm-6 col-form-label fw-600'>
          Price
        </label>
        <div className='col-sm-6'>
          <div className='dropdown'>
            <button
              type='button'
              className='btn btn-transparent border-0'
              id='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
              data-bs-toggle='dropdown'
            >
              <span className='fw-bold'>
                ₦{formatCurrency(minPrice)} - ₦{formatCurrency(maxPrice)}
              </span>
              <i className='iconly-Arrow-Down-Circle icbo ml-2'></i>
            </button>
            <div
              className='dropdown-menu'
              aria-labelledby='dropdown'
              style={{minWidth: 400}}
            >
              <div className='container'>
                <div className='row'>
                  <div className='col-6'>
                    <label htmlFor='customRange1' className='form-label'>
                      Min
                    </label>
                    {
                      <span className='fs-12 d-block'>
                        {' '}
                        ₦{formatCurrency(minPrice)}
                      </span>
                    }
                    <input
                      type='number'
                      className='border-1 fw-bold form-control fs-16'
                      // id='customRange1'
                      // max={500}
                      name='minPrice'
                      value={minPrice}
                      onChange={(e) => {
                        setMinPrice(parseInt(e.target.value));
                      }}
                    />
                  </div>
                  <div className='col-6'>
                    <label htmlFor='customRange2' className='form-label'>
                      Max
                    </label>
                    {
                      <span className='fs-12 d-block'>
                        {' '}
                        ₦{formatCurrency(maxPrice)}
                      </span>
                    }
                    <input
                      type='number'
                      className='border-1 fw-bold form-control fs-16'
                      // id='customRange1'
                      // max={500}
                      name='maxPrice'
                      value={maxPrice}
                      onChange={(e) => {
                        setMaxPrice(parseInt(e.target.value));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* property type */}
      <div className='row mb-5'>
        <label htmlFor='staticEmail' className='col-sm-6 col-form-label fw-600'>
          Property Type
        </label>
        <div className='col-sm-6'>
          {/* <p className='text-secondary'>Property Type</p> */}
          <select
            className='form-select rounded-pill py-2 px-3'
            onChange={(e) => setPropertyType(e.target.value)}
            value={propertyType}
          >
            <option value=''>Select</option>
            {propertyKinds?.map(({id, title}) => {
              return (
                <option key={id} value={title}>
                  {title}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {/* location */}
      {/* <div className='row mb-5'>
        <label htmlFor='staticEmail' className='col-sm-6 col-form-label fw-600'>
          Location
        </label>
        <div className='col-sm-6'></div>
      </div> */}
      {/* location */}
      {/* <div className='row mb-5'>
        <label htmlFor='staticEmail' className='col-sm-6 col-form-label fw-600'>
          Location
        </label>
        <div className='col-sm-6'></div>
      </div> */}
      {/* Intent */}
      <div className='mb-5 row'>
        <label htmlFor='staticEmail' className='col-sm-6 col-form-label fw-600'>
          Intent
        </label>
        <div className='col-sm-6'>
          <select
            className='form-select rounded-pill py-2 px-3'
            value={intent}
            onChange={(e) => setIntent(e.target.value)}
          >
            <option value=''>Select</option>
            <option value='shortlet'>shortlet</option>
            <option value='long lease'>long lease</option>
            <option value='sale'>sale</option>
          </select>
        </div>
      </div>
      {/* Bedroom */}
      <div className='mb-5 row'>
        <label htmlFor='staticEmail' className='col-sm-6 col-form-label fw-600'>
          Bedroom
        </label>
        <div className='col-sm-6'>
          <select
            className='form-select rounded-pill py-2 px-3'
            value={bedroom}
            onChange={(e) => setBedroom(e.target.value)}
          >
            <option value=''>Select</option>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
          </select>
        </div>
      </div>
      {/* Bed */}
      <div className='mb-5 row'>
        <label htmlFor='staticEmail' className='col-sm-6 col-form-label fw-600'>
          Bed
        </label>
        <div className='col-sm-6'>
          <select
            className='form-select rounded-pill py-2 px-3'
            value={bed}
            onChange={(e) => setBed(e.target.value)}
          >
            <option value=''>Select</option>
            <option value='1'>1</option>
            <option value='2'>2</option>
          </select>
        </div>
      </div>
      {/* Bathrooms */}
      <div className='mb-5 row'>
        <label htmlFor='staticEmail' className='col-sm-6 col-form-label fw-600'>
          Bathrooms
        </label>
        <div className='col-sm-6'>
          <select
            className='form-select rounded-pill py-2 px-3'
            value={bathroom}
            onChange={(e) => setBathroom(e.target.value)}
          >
            <option value=''>Select</option>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
          </select>
        </div>
      </div>

      {/* <div className='mb-3'>
            <label className='fw-600 mb-4'>Features</label>
            <div className='form-check mb-4'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                id='defaultCheck1'
              />
              <label className='form-check-label' htmlFor='defaultCheck1'>
                Garage
              </label>
            </div>
            <div className='form-check mb-4'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                id='defaultCheck2'
              />
              <label className='form-check-label' htmlFor='defaultCheck2'>
                Air Conditioning
              </label>
            </div>
          </div> */}
      <div className='row'>
        <button
          className='btn btn-custom-primary  '
          data-bs-dismiss='offcanvas'
          aria-label='Close'
          onClick={handleSearch}
        >
          Search
        </button>
        <button
          className='btn btn-custom-secondary mt-3'
          onClick={handleClearFilters}
        >
          Clear
        </button>
        {/* <PrimaryButton
							type='primary'
							text='Search'
							// width={window.innerWidth >= 512 ? '80%' : '100%'}
						/> */}
      </div>
    </div>
  );
};
