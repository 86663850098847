import React from 'react';
// import locationIcon from '../../assets/icons/location.svg';
import locationIcon from '../../../assets/icons/location2.svg';
import phoneIcon from '../../../assets/icons/phone.svg';
import envelopeIcon from '../../../assets/icons/envelop.svg';
const ContactInfo = () => {
  return (
    <div className=' pb-3'>
      <div className='info container'>
        <div className='row col-11 d-flex gap-4 gap-md-0 gap-lg-4 col-lg-12  mx-auto justify-content-center '>
          <div className=' col-lg-3'>
            <div className='row  bg-white  border-0   border-md-round px-4 py-4 shadow-sm d-flex '>
              <div className='col-3  col-md-auto '>
                <img src={phoneIcon} alt='call us' className='p-0' />
              </div>
              <div className='col-9 '>
                <h6 className='fw-bold '>Call Us</h6>
                <p className='pb-0 mb-0'>+2349118008008</p>
                <span>+2349118008008</span>
              </div>
            </div>
          </div>

          <div className='col-lg-3'>
            <div className='row d-flex item bg-white border-0  px-3 py-3 shadow-sm'>
              <div className='col-3 col-md-auto'>
                <img src={envelopeIcon} alt='' />
              </div>
              <div className='col-9'>
                <h6 className='fw-bold '>Email Us</h6>
                <p className='mb-0'>hello@krent.space</p>
                <p className=''>info@krent.space</p>
              </div>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='row bg-white  border-0  px-4 py-4 shadow-sm d-flex'>
              <div className='col-3 col-md-auto'>
                <img src={locationIcon} alt='' />
              </div>
              <div className='col-9'>
                <h6 className='custom-font fw-bold'>Visit Us</h6>
                <p className='mb-0'>H3 Bayview estate ikate Lekki Lagos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
