// import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './fonts/clash-display/Fonts/WEB/css/clash-display.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import AppRouter from 'routes';
import 'rsuite/dist/rsuite.min.css';
// import {useEffect} from 'react';
import {Helmet} from 'react-helmet';

const isGAEnabled = process.env['REACT_APP_ENABLE_GA'] === 'true';
const gaTrackingId = process.env['REACT_APP_GA_TRACKING_ID'];
const gaConfigId = process.env['REACT_APP_GA_CONFIG_ID'];

const GoogleAnalytics = () => {
  if (!isGAEnabled || !gaTrackingId || !gaConfigId) {
    return (
      <Helmet>
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>
    );
  }

  return null;
};
// const isGAEnabled = process.env['REACT_APP_ENABLE_GA'] === 'true';
// const gaTrackingId = process.env['REACT_APP_GA_TRACKING_ID'];
// const gaConfigId = process.env['REACT_APP_GA_CONFIG_ID'];

// const GoogleAnalytics = () => {
//   useEffect(() => {
//     if (isGAEnabled && gaTrackingId && gaConfigId) {
//       const script1 = document.createElement('script');
//       script1.async = true;
//       script1.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`;
//       document.head.appendChild(script1);

//       const script2 = document.createElement('script');
//       script2.innerHTML = `
//         window.dataLayer = window.dataLayer || [];
//         function gtag(){dataLayer.push(arguments);}
//         gtag('js', new Date());
//         gtag('config', '${gaConfigId}');
//       `;
//       document.head.appendChild(script2);
//     }
//   }, []);

//   return null;
// };

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AppRouter />
    <GoogleAnalytics />
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
