type KycData = {
  photo: string;
  documentID: string;
  documentType: string;
  expirationDate: string;
  agentId: string;
  agentIdNumber: string;
};

// const agentFormInput = () => {
//   return <div>agentFormInput</div>;
// };
// export default agentFormInput;

export const AgentFormInput = ({
  user,
  handleKycOnChange,
  kycData,
  loading,
  handleAgentKycSubmit,
  previewUrl,
  file,
}: {
  user: any;
  handleKycOnChange: (e: any) => void;
  kycData: KycData;
  loading: boolean;
  handleAgentKycSubmit: any;
  previewUrl: string;
  file: any;
}) => {
  return (
    <div className=''>
      {user.role === 'agent' && (
        <div className='row'>
          <div className='col-md-6 col-12 mb-4'>
            <label className='fw-bolder form-label text-capitalize'>
              Agent ID Number
            </label>
            <input
              type='text'
              className='form-control rounded-pill py-3 px-4 fs-14 mb-3'
              onChange={handleKycOnChange}
              value={kycData.agentIdNumber}
              // required
              name='agentIdNumber'
              placeholder='Input Agent ID Number'
            />
          </div>
          <div className='col-md-6 col-12 mb-4'>
            <label className='fw-bolder form-label text-capitalize'>
              Agent ID
            </label>
            <div className='input-group mb-3'>
              <input
                type='file'
                className='form-control border-end-0 border-rounded-5 py-3 px-4 rounded-pill-start'
                placeholder='No file selected'
                id='inputGroupFile01'
                name='agentId'
                onChange={handleKycOnChange}
              />
              <label
                htmlFor='inputGroupFile01'
                className='input-group-text rounded-pill-end bg-transparent text-link text-decoration-none border-start-0 pe-4'
              >
                Upload
              </label>
            </div>
            {/* preview */}
            {previewUrl && (
              <div>
                {file.type === 'image/jpeg' ? (
                  // Render image preview if the file is an image
                  <img
                    src={previewUrl}
                    alt='File Preview'
                    style={{
                      maxWidth: '100%',
                      maxHeight: '200px',
                    }}
                  />
                ) : file.type === 'application/pdf' ? (
                  // Render PDF preview using <embed> or <iframe> if the file is a PDF
                  // <embed
                  //   src={URL.createObjectURL(
                  //     new Blob([previewUrl], {type: 'application/pdf'})
                  //   )}
                  //   type='application/pdf'
                  //   width='100%'
                  //   height='400px'
                  // />
                  <iframe
                    src={previewUrl}
                    width='100%'
                    height='200px'
                    style={{border: '1px solid #ccc'}}
                    title='PDF Preview'
                  />
                ) : (
                  // Fallback message if the file type is not supported for preview
                  <p>Preview not available for this file type</p>
                )}
              </div>
            )}
            {/* preview*/}
          </div>
          <div className='row'>
            <div>
              <button
                type='submit'
                className='btn btn-primary rounded-pill py-3 px-5 fw-bold fs-14 col-12 col-md-4'
                disabled={loading}
                onClick={handleAgentKycSubmit}
              >
                {loading ? (
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  ></span>
                ) : (
                  'Submit Agent KYC'
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
