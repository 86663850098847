import {useSessionStorage} from '../../../hooks';
import {DashboardLayout} from '@layouts/index';
import image from '../../../assets/images/profile-pic.png';
import {
  sendOTP,
  updateProfile,
  validateOTP,
  updateProfilePic,
  addNationalId,
  // getProfile,
} from '@services/krent.service';
import {useState} from 'react';
import {Modal} from '@shared/controls/Modal';
import ktoast from '@services/toast.service';
import SuccessCard from '@shared/cards/SuccessCard';
import {useNavigate} from 'react-router-dom';

export const EditProfile = () => {
  const [user, setUser] = useSessionStorage('user', {});

  const navigate = useNavigate();

  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [nationality, setNationality] = useState<string>('');
  const [otherCountryName, setOtherCountryName] = useState<string>('');
  const [idNumber, setIdNumber] = useState<string>('');
  const [id, setId] = useState<any>('');
  const [expiry, setExpiry] = useState<string>('');
  const [type, setType] = useState<string>('');

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [profilePic, setProfilePic] = useState<any>(false);
  const [profilePicUrl, setProfilePicUrl] = useState<any>(false);
  const [otpError, setOtpError] = useState<boolean>(false);
  const [otpErrorMsg, setOtpErrorMsg] = useState<string>('');
  const [otpSuccessMsg, setOtpSuccessMsg] = useState<string>('');
  const [isVerifying, setIsVerifiying] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (e: any) => {
    const value = e.target.files;
    // const name = e.target.name
    const [file] = value;
    setProfilePic(value);
    setProfilePicUrl(URL.createObjectURL(file));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const abortController = new AbortController();

    setLoading(true);

    if (profilePic) {
      const formData = new FormData();
      formData.append('photo', profilePic[0]);
      updateProfilePic(user?.id, formData, abortController.signal)
        .then((res: any) => {
          setUser(res.data);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            navigate('/profile');
          }, 1000);
        });
    }

    if (id || idNumber || expiry || type) {
      const formData = new FormData();
      formData.append('number', idNumber);
      formData.append('photo', id[0]);
      formData.append('type', type);
      formData.append('expiryDate', expiry);
      addNationalId(user?.id, formData, abortController.signal)
        .then((res: any) => {
          ktoast.success(
            `Identification Id added successfully for ${res.firstname} `
          );
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            navigate('/profile');
          }, 1000);
        });
    }

    if (firstname || lastname || phone || nationality || otherCountryName) {
      updateProfile(
        user?.id,
        {
          firstname: firstname || user.firstname,
          lastname: lastname || user.lastname,
          phone: phone || user.phone,
          nationality: otherCountryName || nationality || user.nationality,
        },
        abortController.signal
      )
        .then((res: any) => {
          // getProfile(abortController.signal).then((res: any) => {
          setUser(res.data);
          // });
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            navigate('/profile');
          }, 1000);
        });
    }
  };

  const handleVerifyOTP = () => {
    setLoading(true);
    const code = Array.prototype.map
      .call(document.getElementsByClassName('digits'), (e) => e.value)
      .join('');
    let abortController = new AbortController();
    setIsVerifiying(true);
    validateOTP({otp: code}, abortController.signal)
      .then(() => {
        setLoading(false);
        // setModalOpen(false);
        setOtpSuccessMsg('Phone number verified successfully.');
        ktoast.success('Phone number verified successfully.');
        setUser({...user, verified: [...user?.verified, 'phone']});
        // ktoast.error('Failed to validate OTP');
      })
      .catch((err) => {
        setOtpError(true);
        setLoading(false);
        ktoast.error(err.errors[0].message);
        setOtpErrorMsg(err.errors[0].message);
        setTimeout(() => {
          setOtpErrorMsg('');
        }, 3000);
      })
      .finally(() => {
        setIsVerifiying(false);
        setLoading(false);
      });
  };

  const verifyPhone = () => {
    let abortController = new AbortController();
    setLoading(true);
    sendOTP(abortController.signal)
      .then((res) => {
        console.log(res);

        setModalOpen(true);
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
        setLoading(false);
        // setModalOpen(true);
      })
      .finally(() => {
        setLoading(false);
        // setModalOpen(false);
      });
  };

  // useEffect(() => {
  //   console.log(user);
  // }, [user]);

  return (
    <DashboardLayout>
      <div className='row mb-3'>
        <h3 className='fw-bold mb-1'>Edit Your Profile</h3>
        <p className='text-secondary fs-14'>Update your profile on Krent</p>
      </div>

      <div className='row'>
        <div className='container'>
          <div className='card border-0 border-round shadow-sm py-5'>
            <div className='card-body px-md-5 px-4 d-inline-block d-md-flex gap-5'>
              <div className='row '>
                <div className='col-12 col-md-5 mb-5 mb-md-0'>
                  <div className='align-self-center text-center position-relative '>
                    <img
                      src={
                        profilePicUrl
                          ? profilePicUrl
                          : user.photo !== 'default.jpg'
                          ? `${user?.photo}`
                          : image
                      }
                      alt={user ? user?.preferredName : 'Avatar'}
                      style={{objectFit: 'cover'}}
                      height={100}
                      width={100}
                      className='rounded-circle '
                    />
                    <input
                      type='file'
                      id='upload-circle'
                      hidden
                      name='profilePic'
                      onChange={handleChange}
                    />

                    {/* top-50 start-50 translate-middle */}

                    <label
                      htmlFor='upload-circle'
                      className='me-auto rounded-circle d-flex align-items-center justify-content-center mb-2 bg-dark opacity-75 position-absolute top-0 start-0  cursor-pointer'
                      style={{height: 100, width: 100}}
                    >
                      <i className='fa-light fa-camera fs-22 text-white'></i>
                    </label>
                  </div>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6 col-12 mb-5'>
                    <label className='fw-bolder form-label text-capitalize'>
                      First name
                    </label>
                    <input
                      type='text'
                      className='form-control rounded-pill py-3 px-4 fs-14'
                      defaultValue={firstname ? firstname : user?.firstname}
                      onChange={(e) => {
                        setFirstname(e.target.value);
                      }}
                    />
                  </div>
                  <div className='col-md-6 col-12 mb-5'>
                    <label className='fw-bolder form-label text-capitalize'>
                      Last name
                    </label>
                    <input
                      type='text'
                      className='form-control rounded-pill py-3 px-4 fs-14'
                      defaultValue={lastname ? lastname : user?.lastname}
                      onChange={(e) => {
                        setLastname(e.target.value);
                      }}
                    />
                  </div>
                  <div className='col-md-6 col-12 mb-5'>
                    <label className='fw-bolder form-label text-capitalize'>
                      Preferred name
                    </label>
                    <input
                      type='text'
                      className='form-control rounded-pill py-3 px-4 fs-14'
                      defaultValue={user?.preferredName}
                      disabled
                    />
                    <span className='fs-10 ml-3 text-danger'>
                      You can not edit your preferred Name
                    </span>
                  </div>
                  {/* <div className='col-md-6 col-12 mb-5'>
                    <label className='fw-bolder form-label text-capitalize'>
                      Gender
                    </label>
                    <select className='form-select rounded-pill py-3 px-4 fs-14'>
                      <option value='Male'>Male</option>
                      <option value='Female'>Female</option>
                    </select>
                  </div> */}
                  <div className='col-md-6 col-12 mb-5'>
                    <label className='fw-bolder form-label text-capitalize'>
                      Nationality
                    </label>
                    <select
                      className='form-select rounded-pill py-3 px-4 fs-14'
                      value={nationality}
                      onChange={(e) => {
                        setNationality(e.target.value);
                      }}
                    >
                      <option>Select Country</option>
                      <option value='nigeria'>Nigerian</option>
                      <option value='others'>Others</option>
                    </select>
                    {nationality === 'others' && (
                      <input
                        type='text'
                        className='form-control mt-3'
                        placeholder='Enter Country Name'
                        value={otherCountryName}
                        onChange={(e) => {
                          setOtherCountryName(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className='col-md-6 col-12 mb-5'>
                    <label className='fw-bolder form-label text-capitalize'>
                      Phone number{' '}
                      {!user?.verified.includes('phone') && (
                        <span>
                          <i className='iconly-Danger icli text-danger fs-14'></i>
                        </span>
                      )}
                    </label>
                    <input
                      type='text'
                      className='form-control rounded-pill py-3 px-4 fs-14'
                      defaultValue={phone ? phone : user?.phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                    {!user?.verified.includes('phone') && (
                      <div className='form-text ms-3 mt-2'>
                        Phone number not verified.{' '}
                        <button
                          className='btn btn-link fs-14 text-decoration-none p-0'
                          onClick={verifyPhone}
                        >
                          Verify now
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-12 mb-4'>
                    <label className='fw-bolder form-label text-capitalize'>
                      Nationality ID
                    </label>
                    <select
                      className='form-select rounded-pill py-3 px-4 fs-14 mb-3'
                      value={type}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    >
                      <option>Identification Type</option>
                      <option value='national id'>National ID</option>
                      <option value='international passport'>
                        International Passport
                      </option>
                      <option value='voters card'>Voters Card</option>
                      <option value='drivers license'>Drivers license</option>
                    </select>
                  </div>
                  <div className='col-md-6 col-12 mb-4'>
                    <label className='fw-bolder form-label text-capitalize'>
                      ID Number
                    </label>
                    <input
                      type='text'
                      className='form-control rounded-pill py-3 px-4 fs-14 mb-3'
                      defaultValue={idNumber}
                      onChange={(e) => {
                        setIdNumber(e.target.value);
                      }}
                      placeholder='Input ID Number'
                    />
                  </div>
                  <div className='row'>
                    <div className='col-md-6 col-12 mb-4'>
                      <label className='fw-bolder form-label text-capitalize'>
                        Expiry Date
                      </label>
                      <div className='input-group mb-3'>
                        <input
                          type='date'
                          className='form-control border-end-0 border-rounded-5 py-3 px-4 rounded-pill-start'
                          defaultValue={expiry}
                          onChange={(e) => {
                            setExpiry(e.target.value);
                          }}
                          id='expiryDate'
                          min={
                            new Date(new Date().getTime() + 86400000)
                              .toISOString()
                              .split('T')[0]
                          }
                        />
                        <label
                          className='input-group-text rounded-pill-end bg-transparent text-link text-decoration-none border-start-0 pe-4'
                          htmlFor='expiryDate'
                        >
                          ID Expiry Date
                        </label>
                      </div>
                    </div>
                    <div className='col-md-6 col-12 mb-4'>
                      <label className='fw-bolder form-label text-capitalize'>
                        Choose ID
                      </label>
                      <div className='input-group mb-3'>
                        <input
                          type='file'
                          className='form-control border-end-0 border-rounded-5 py-3 px-4 rounded-pill-start'
                          placeholder='No file selected'
                          id='inputGroupFile02'
                          onChange={(e) => {
                            setId(e.target.files);
                          }}
                        />
                        <label
                          className='input-group-text rounded-pill-end bg-transparent text-link text-decoration-none border-start-0 pe-4'
                          htmlFor='inputGroupFile02'
                        >
                          Upload ID
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div>
                      <button
                        type='submit'
                        className='btn btn-primary rounded-pill py-3 px-5 fw-bold fs-14'
                        disabled={loading}
                      >
                        {loading ? (
                          <span
                            className='spinner-border spinner-border-sm'
                            role='status'
                            aria-hidden='true'
                          ></span>
                        ) : (
                          'Save Preferences'
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={isModalOpen}
        onClose={() => {
          setModalOpen(false);
          setOtpSuccessMsg('');
        }}
      >
        <div className='container height-100 d-flex justify-content-center align-items-center'>
          {otpSuccessMsg ? (
            <SuccessCard text={otpSuccessMsg} />
          ) : (
            <div className='position-relative'>
              <div className='px-2 text-center'>
                <p className='fs-16'>
                  Please enter the one time password to verify your account
                </p>
                {otpErrorMsg ? (
                  <p className='text-danger'>{otpErrorMsg}</p>
                ) : otpSuccessMsg ? (
                  <p className='text-success'>{otpSuccessMsg}</p>
                ) : (
                  <p className='fs-14'>
                    A code has been sent to{' '}
                    <span className='fw-bold'>
                      *******{user?.phone.slice(-4)}
                    </span>
                  </p>
                )}

                <div
                  id='otp'
                  className={`inputs d-flex flex-row justify-content-center mt-2${
                    otpError ? ' invalid-otp' : ''
                  }`}
                >
                  <input
                    className='m-2 py-3 px-3 text-center form-control rounded digits'
                    type='text'
                    id='first'
                    maxLength={1}
                    onFocus={() => setOtpError(false)}
                    onChange={(e) => {
                      if (e.target.value) {
                        document!.getElementById('second')!.focus();
                      }
                    }}
                  />
                  <input
                    className='m-2 py-3 px-3 text-center form-control rounded digits'
                    type='text'
                    id='second'
                    maxLength={1}
                    onChange={(e) => {
                      if (e.target.value) {
                        document!.getElementById('third')!.focus();
                      }
                    }}
                  />
                  <input
                    className='m-2 py-3 px-3 text-center form-control rounded digits'
                    type='text'
                    id='third'
                    maxLength={1}
                    onChange={(e) => {
                      if (e.target.value) {
                        document!.getElementById('fourth')!.focus();
                      }
                    }}
                  />
                  <input
                    className='m-2 py-3 px-3 text-center form-control rounded digits'
                    type='text'
                    id='fourth'
                    maxLength={1}
                    onChange={(e) => {
                      if (e.target.value) {
                        document!.getElementById('fifth')!.focus();
                      }
                    }}
                  />
                  <input
                    className='m-2 py-3 px-3 text-center form-control rounded digits'
                    type='text'
                    id='fifth'
                    maxLength={1}
                    onChange={(e) => {
                      if (e.target.value) {
                        document!.getElementById('sixth')!.focus();
                      }
                    }}
                  />
                  <input
                    className='m-2 py-4 px-3 text-center form-control rounded digits'
                    type='text'
                    id='sixth'
                    maxLength={1}
                  />
                </div>
                <div className='mt-4'>
                  <button
                    className='btn btn-primary rounded-pill px-5 py-2'
                    onClick={handleVerifyOTP}
                  >
                    Validate
                  </button>
                </div>
              </div>
              <div className='mt-3 text-center'>
                <div className='content d-flex justify-content-center align-items-center'>
                  <button
                    disabled={isVerifying}
                    className='btn btn-link fs-14 text-decoration-none'
                  >
                    Didn't get the code?{' '}
                    <span className='text-dark' onClick={verifyPhone}>
                      Resend
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </DashboardLayout>
  );
};
