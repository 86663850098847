import {useSessionStorage} from '../../hooks';
import {useState} from 'react';
import {
  // getFirestore,
  // collection,
  // addDoc,
  serverTimestamp,
  Timestamp,
  doc,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';
// import PropertyVideo from '@shared/Video';

import {firestore} from '@services/firebase.service';
import ktoast from '@services/toast.service';
import {renewBooking} from '@services/krent.service';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import {useNavigate} from 'react-router-dom';
import {formatCurrency} from '@utils/currency';
import {CycleToInterval} from '@utils/constants';
import {formatDate} from '@utils/helpers';

const ExtendBooking = ({
  transactionId,
  // listingId,
  type,
  // owner,
  // ownerName,
  currency,
  duration,
  price,
  members,
  selectedMessage,
  setTransaction,
}: any) => {
  const [user] = useSessionStorage('user', {});
  const [loading, setLoading] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState<any | null>(null);
  const [dateTo, setDateTo] = useState<any | null>(null);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  // const [dateFrom, setDateFrom] = useState(new Date().toDateString());
  // const [dateTo, setDateTo] = useState(new Date().toDateString());
  // const [showModal, setShowModal] = useState<boolean>(false);

  const datePickerMinDate = new Date();
  const messageCollections: any = process.env['REACT_APP_FIREBASE_COLLECTION'];
  // const navigate = useNavigate();

  // Function to calculate min date
  const handleMinDate = (selectedDate: Date | null) => {
    let minDate;
    // Calculate a future date (e.g., one day ahead) and set it as the min date
    if (selectedDate) {
      minDate = new Date(selectedDate);
      minDate.setDate(minDate.getDate() + 1);
      return minDate;
    }
    minDate = new Date();
    return minDate;
  };

  const handleRenewBooking = async () => {
    if (user && user?.id) {
      setLoading(true);
      const formattedDateFrom = formatDate(dateFrom);
      const formattedDateTo = formatDate(dateTo);
      try {
        const d: any = await renewBooking({
          originalTransaction: transactionId,
          arrival_date: formattedDateFrom,
          departure_date: formattedDateTo,
        });

        setTransaction(d?.data);

        const docRef = doc(firestore, messageCollections, selectedMessage);
        await updateDoc(docRef, {
          meta: {
            request: {
              id: d?.data?.id,
              property: d?.data.listing?.id,
              request_type: type,
              arrival_date: dateFrom,
              departure_date: dateTo,
            },
          },
          chats: arrayUnion({
            from_uid: user?.id,
            to_uid: user?.id === members[0] ? members[1] : members[0],
            type: 'text',
            value: 'I would like to extend my booking.',
            createdAt: Timestamp.now(),
          }),
          read: false,
          updatedAt: serverTimestamp(),
        });

        setSuccess(d?.message);
        ktoast.success('Your booking has been renewed successfully.');
        // Clear message
        // Scroll to bottom
      } catch (err: any) {
        if (err.errors && err.errors[0]) {
          ktoast.error(err.errors[0].message);
          setError(err.errors[0].message);
          setTimeout(() => {
            setError('');
          }, 3000);
        } else {
          console.error('Error updating document: ', err);
          ktoast.error(
            'Failed to send message. Please check you have a working internet connection'
          );
        }
      } finally {
        setLoading(false);
      }
    } else {
      // setShowModal(true);
      setLoading(false);
    }
  };

  return (
    <div>
      <div className='border-1 rounded border-purple'>
        <div className='d-flex flex-column p-4 h-100'>
          <div className='d-flex flex-column'>
            <p className='light-text'>
              {type === 'long lease'
                ? 'Rent'
                : type === 'shortlet'
                ? 'Shortlet'
                : 'Sale'}{' '}
              Price
            </p>
            <div className=' d-flex d-md-block d-lg-flex   justify-content-between align-items-center mt-neg'>
              <p className='fs-12'>
                <span className='fs-22 fw-bolder'>
                  {currency}
                  {formatCurrency(price)}
                </span>
                {type !== 'sale' && <span> / {CycleToInterval(duration)}</span>}
              </p>
              <p>
                {type === 'sale' && (
                  <span className='badge d-inline-flex fs-12 mb-3 px-2 py-2 fw-semibold text-custom-royal bg-custom-royal bg-opacity-10 border border-success border-opacity-10 rounded-pill'>
                    FOR SALE
                  </span>
                )}
                {type === 'long lease' && (
                  <span className='badge d-inline-flex fs-12 mb-3 px-2 py-2 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-pill'>
                    RENTAL
                  </span>
                )}
                {type === 'shortlet' && (
                  <span className='badge d-inline-flex fs-12 mb-3 px-2 py-2 fw-semibold text-primary bg-primary bg-opacity-10 border border-primary border-opacity-10 rounded-pill'>
                    SHORT LET
                  </span>
                )}
              </p>
            </div>
          </div>

          <hr />

          <div className=''>
            <h5 className='fw-bold mb-2'>
              {type === 'shortlet'
                ? 'Reservations'
                : type === 'long lease'
                ? 'Rent Duration'
                : 'Contact Owner'}
            </h5>
            {success && <span className='fs-13 text-success'>{success}</span>}

            {/* Display the error message */}
            {error && <span className='fs-13 text-danger'>{error}</span>}

            {/* Date From Input */}
            {type !== 'sale' && (
              <div className='form-group m-1'>
                <label className='form-label fs-14'>
                  {type === 'shortlet' ? 'Check In' : 'From'}
                </label>
                <div className='input-group'>
                  <span className='input-group-text input-group-text-0'>
                    <i className={`iconly-Calendar`}></i>
                  </span>
                  <div className='form-control form-control-0   border-start-0'>
                    <DatePicker
                      selected={dateFrom}
                      onChange={(date) => setDateFrom(date)}
                      placeholderText='dd-mm-yyyy'
                      className='form-control form-control-0  border-0'
                      minDate={datePickerMinDate}
                      showYearDropdown
                      showMonthDropdown
                      // showTimeSelect
                      dateFormat='dd-MM-yyyy'
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Date To Input */}
            {type !== 'sale' && (
              <div className='form-group mb-3'>
                <label className='form-label fs-14'>
                  {type === 'shortlet' ? 'Check Out' : 'To'}
                </label>
                <div className='input-group'>
                  <span className='input-group-text input-group-text-0'>
                    <i className={`iconly-Calendar`}></i>
                  </span>
                  <div className='form-control form-control-0   border-start-0'>
                    <DatePicker
                      selected={dateTo}
                      onChange={(date: any) => setDateTo(date)}
                      placeholderText='dd-mm-yyyy'
                      className='form-control form-control-0   border-0'
                      minDate={handleMinDate(dateFrom)}
                      showYearDropdown
                      showMonthDropdown
                      disabled={!dateFrom}
                      // showTimeSelect
                      dateFormat='dd-MM-yyyy'
                      // dateFormat='yyyy-MM-dd'
                    />
                  </div>
                </div>
              </div>
            )}
            {/* Button */}
            <div className='d-grid my-4 gap-3'>
              <button
                className='btn btn-primary py-3 rounded-pill'
                disabled={
                  loading ||
                  (!dateFrom && type !== 'sale') ||
                  (!dateTo && type !== 'sale')
                }
                onClick={() => {
                  // setLoading(true);
                  handleRenewBooking();
                }}
              >
                {loading ? (
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  ></span>
                ) : (
                  'Book Now'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExtendBooking;
