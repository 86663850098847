import { TransactionListResponse } from '@interfaces/transactions.interface';

export const transactions: TransactionListResponse[] = [
  {
    description: 'Short Let Payment',
    property: 'Michael’s Apartment',
    value: 60000,
    time: '10:00PM',
    type: 'inc',
  },
  {
    description: 'Rent Payment',
    property: 'Michael’s Apartment',
    value: 60000,
    time: '10:00PM',
    type: 'inc',
  },
  {
    description: 'Withdrawal',
    value: 60000,
    time: '10:00PM',
    type: 'exp',
  },
];

export const invoices: TransactionListResponse[] = [
  // {
  // 	description: 'Invoice to Michael John For Michael’s Apartment',
  // 	value: 60000,
  // 	time: '10:00PM',
  // },
  // {
  // 	description: 'Invoice to Michael John For Michael’s Apartment',
  // 	value: 60000,
  // 	time: '10:00PM',
  // },
  // {
  // 	description: 'Invoice to Michael John For Michael’s Apartment',
  // 	value: 60000,
  // 	time: '10:00PM',
  // },
];

export const bankAccount = {
  bankName: 'Wema Bank',
  accountNumber: '0237951112',
  accountName: 'Michael Jones',
};

export const balance: number = 0;
