type Props = {
  type: string;
  label?: string;
  placeholder: string;
  icon?: string;
  iconType?: 'iconly' | 'fa';
  value?: string;
  change: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  length?: number;
  passwordIcon?: string;
  from?: string;
  to?: string;
  min?: any;
};

export const InputComponent: React.FC<Props> = ({
  type,
  label,
  placeholder,
  icon = 'Message',
  iconType = 'iconly',
  value,
  change,
  required = false,
  length,
  passwordIcon,
  min,

  from,
  to,
}: Props) => {
  return (
    <div className={`form-group m-1`}>
      <span className='fs-12 '>{from}</span>
      <span className='m-0 mt-2 d-inline-block fs-12'>{to}</span>
      <label className={`text-uppercase fw-600 fs-12 m-1`} htmlFor={type}>
        {label}
      </label>

      <div className='input-group'>
        <span
          className='input-group-text input-group-text-0 '
          id='basic-addon1'
        >
          {iconType === 'iconly' ? (
            <i className={`iconly-${icon} icli fs-4`}></i>
          ) : (
            <i className={`fa-light fa-${icon} fs-5`}></i>
          )}
        </span>

        <input
          type={type}
          className='form-control form-control-0 ps-3 py-3 border-start-0'
          id={type}
          aria-describedby='emailHelp'
          placeholder={placeholder}
          onChange={change}
          value={value}
          required={required}
          maxLength={length}
          min={min}
        />
        {passwordIcon && (
          <span className='input-group-text input-group-text-0 '>
            {passwordIcon === 'open' ? (
              <i
                className='iconly-Show icli fs-4'
                style={{ cursor: 'pointer', color: '#05569f' }}
              ></i>
            ) : (
              <i
                className='iconly-Hide icli fs-4'
                style={{ cursor: 'pointer', color: '#05569f' }}
              ></i>
            )}
          </span>
        )}
      </div>
    </div>
  );
};
