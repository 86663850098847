import React, {useState} from 'react';
import {BaseLayout} from '@layouts/BaseLayout';
import Accordion from 'react-bootstrap/Accordion';
import PageHero from '@shared/InnerPageHero';
import {items, category} from './data';
import GetMeta from '@pages/SeoPages/GetMeta';

const Faq = () => {
  const [index, setIndex] = useState<number>(0);

  return (
    <BaseLayout>
      <GetMeta
        description='Find answers to the most frequently asked questions about using Krent site. Get information on account setup, property listings, contacting agents, and more to help you navigate our services smoothly.'
        title={`Frequently Asked Question | Krent Inc`}
        canonicalValue={'faq'}
      />
      <section className='mb-5' style={{marginTop: '-2rem'}}>
        <PageHero img='/assets/inner-pagebg.jpg' currentPage='faq' />
      </section>
      <section className='container-fluid  mb-5'>
        <div className='container'>
          <div className='text-center mb-5'>
            <h1 className='fw-bolder '>
              <span>Frequently </span> Asked Questions
            </h1>
            <div className='d-flex justify-content-center row'>
              <p className=' col-md-9 col-lg-7'>
                Welcome to our FAQ page, where you can find answers to all of
                your questions about buying, selling, and letting property.
                Whether you're a first-time home buyer or a seasoned real estate
                investor, our comprehensive FAQs cover everything from the home
                buying process to property maintenance. Browse our categories to
                find the information you need, With up-to-date information and
                expert advice, our FAQ page is your ultimate resource for all
                inquiries.
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-4 mb-5 mb-md-0 '>
              <div className='card'>
                <p className='fw-bolder mb-1 card-header'>Categories</p>
                <ul className='list-group list-group-flush'>
                  {category.map((item: any, i: number) => (
                    <li
                      className={`list-group-item d-flex align-items-center   ${
                        i === index ? 'active' : ''
                      } cursor-pointer fw-bold`}
                      onClick={() => setIndex(i)}
                      key={i}
                    >
                      <i
                        className={`${item.icon} mr-2 `}
                        aria-hidden='true'
                      ></i>{' '}
                      {item.title}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className=' col-md-8'>
              <Accordion defaultActiveKey={items[index][0].id}>
                {items[index].map((i: any, ind: number) => {
                  return (
                    <Accordion.Item eventKey={i.id} key={ind}>
                      <Accordion.Header className='fw-bold text-primary'>
                        {i.question}
                      </Accordion.Header>
                      <Accordion.Body>{i.answer}</Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <section className='py-5'>
        <div className='container text-center'>
          <h4 className='fw-bolder'>Cant Find Your Question?</h4>
          <div className='d-grid gap-3 d-md-block my-4'>
            <button className='btn btn-custom-primary py-3 px-4 rounded-pill me-2 fs-14 fw-600'>
              Call +2349118008008
            </button>
            {/* <Link
              to='/explore/'
              className='btn btn-white border-dark py-3 px-3 rounded-pill fs-14 fw-600'
            >
              Browse Properties
            </Link> */}
          </div>
        </div>
      </section>
    </BaseLayout>
  );
};

export default Faq;
