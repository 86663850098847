import {Link} from 'react-router-dom';

const KycAlert = () => {
  return (
    <div className='row'>
      <div className='col-12'>
        <div
          role='alert'
          className='alert alert-danger alert-dismissible fade show text-black  d-flex justify-content-between align-items-center'
        >
          {/* <i className={`iconly-alert  icli fs-4`}></i> */}
          Please Complete your KYC.
          <Link to='/settings' className='btn btn-success'>
            Add KYC
          </Link>
        </div>
      </div>
    </div>
  );
};
export default KycAlert;
